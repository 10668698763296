import "./invidi-goal-form.scss";
import Vue from 'vue/dist/vue.esm'
import LocationsForm from './invidi-goal-form-app'

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("goal-form"))
       .forEach((element) => {
         const goal = JSON.parse(element.dataset.goal);
         new Vue({
                   data: {
                     goal: goal,
                   },
                   template: "<LocationsForm :original_goal='goal'/>",
                   components: {
                     LocationsForm,
                   }
                 }).$mount(element);
       });
});
