<template>
  <div class="keyword-metrics">
    <h5><strong>Top SEA Keywords</strong></h5>

    <div v-if="loading">Loading...</div>

    <div v-else-if="keyword_metrics.length">
      <table>
        <thead class="keyword-metrics--thead">
        <tr>
          <th class="keyword-metrics--left-aligned">Keyword für Suchanfragen</th>
          <th><abbr title="Conversions">CONVS</abbr></th>
          <th><abbr title="Conversion rate">CVR</abbr></th>
          <th><abbr title="Cost per conversion">CPO</abbr></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="keyword_metric in keyword_metrics">
          <td class="keyword-metrics--left-aligned">{{ keyword_metric.keyword_text }}</td>
          <td>
            <vue-numeric v-if="typeof keyword_metric.conversions === 'number'"
                         :value=keyword_metric.conversions
                         separator="."
                         v-bind:precision=2
                         v-bind:read-only=true>
            </vue-numeric>
          </td>
          <td>
            <percent-rate :rate="keyword_metric.conversion_rate"></percent-rate>
          </td>
          <td>
            <vue-numeric
                v-if="typeof keyword_metric.cpo === 'number' && keyword_metric.cpo !== Infinity"
                v-model="keyword_metric.cpo"
                separator="."
                v-bind:precision=2
                v-bind:read-only=true
                currency="€"
                currency-symbol-position="suffix">
            </vue-numeric>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-else>No reports yet</div>
  </div>
</template>

<script>
import "./keyword-metrics.scss"
import axios from "axios";
import PercentRate from "../percent-rate/percent-rate.vue";
import VueNumeric from 'vue-numeric'

export default {
  name:       "keyword-metrics",
  props:      {
    campaign: {
      type:     Object,
      required: true,
    },
  },
  components: {
    PercentRate,
    VueNumeric,
  },
  data() {
    return {
      keyword_metrics: [],
      loading:         false,
    }
  },
  methods:  {},
  computed: {},
  mounted() {
    if (this.campaign.include_keyword_metrics) {
      this.loading = true
      axios({
        method: 'get',
        url: `/campaigns/${this.campaign.id}/keyword_metrics.json`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then(response => {
        this.loading = false
        this.keyword_metrics = response.data
      }).catch(error => {
        this.loading = false
        console.log("ERROR", error.response.data)
        alert("Error loading keyword metrics")
      })
    }
  },
}
</script>
