<template>
  <div class="base-modal" @click.stop.prevent="closed_requested">
    <dialog open class="base-modal--body" @click.stop="">
      <slot></slot>
    </dialog>
  </div>
</template>

<script>
import "./base-modal.scss";

export default {
  name: "base-modal",
  props: [],
  components: {},
  data() {
    return {}
  },
  methods: {
    closed_requested() {
      this.$emit("closed_requested")
    },
  },
  computed: {},
}
</script>
