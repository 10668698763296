<template>
  <div class="advertiser-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_advertiser_dialog">
        <h3 v-if="advertiser.persisted"> Advertiser aktualisieren</h3>
        <h3 v-else>Neuer Advertiser</h3>

        <form @submit.prevent="save_advertiser" ref="form">
          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <label v-if="advertiser.persisted">
              ID:
              <input type="text"
                     v-model="advertiser.id"
                     :disabled="true">
            </label>
            <label>
              Titel:
              <input type="text"
                     v-model="advertiser.title"
                     placeholder="Titel">
            </label>

            <input id="advertiser_individual_approval" type="checkbox" v-model="advertiser.individual_approval">
            <label for="advertiser_individual_approval">
              Individuelle Report Freischaltung:
            </label>
          </fieldset>

          <div>
            <a v-if="advertiser.persisted"
               @click.stop.prevent="delete_advertiser"
               :class="disabled_button_class"
               class="advertiser-dialog--button--alert">
              <i class="fi-alert"></i>
              Löschen ...
            </a>
            <a @click="close_advertiser_dialog" class="advertiser-dialog--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </a>
            <button v-if="advertiser_configured"
                    :class="disabled_button_class">
              <i class="fi-save"></i>
              <span v-if="advertiser.persisted">Advertiser aktualisieren</span>
              <span v-else>Advertiser hinzufügen</span>
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./advertiser-dialog.scss"
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios"

export default {
  name: "advertiser-dialog",
  props: {
    advertiser: {
      required: false,
      default() {
        return {
          id: null,
          title: null,
          persisted: false,
        }
      },
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      errors: null,
    }
  },
  methods: {
    save_advertiser() {
      if (!this.advertiser_configured) return
      if (this.loading) return

      this.loading = true
      this.errors = null

      if (this.advertiser.persisted) {
        axios({
          method: 'post',
          url: `/advertisers/${this.advertiser.id}/update`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.advertiser.id,
            title: this.advertiser.title,
            individual_approval: this.advertiser.individual_approval,
          },
        }).then((_response) => {
          this.loading = false
          this.close_advertiser_dialog()
        }).catch(error => {
          this.loading = false
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving advertiser")
        })
      } else {
        axios({
          method: 'post',
          url: `/advertisers/register`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.advertiser.id,
            title: this.advertiser.title,
            individual_approval: this.advertiser.individual_approval,
          },
        }).then((_response) => {
          this.loading = false
          this.close_advertiser_dialog()
        }).catch(error => {
          this.loading = false
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving advertiser")
        })
      }
    },
    delete_advertiser() {
      let confirmation = confirm("Willst du den Advertiser wirklich löschen?")
      if (!confirmation) return
      axios({
        method: 'delete',
        url: `/advertisers/${this.advertiser.id}/delete`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then((_response) => {
        this.loading = false
        this.close_advertiser_dialog()
      }).catch(error => {
        this.loading = false
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data)
        alert("Error deleting advertiser")
      })
    },
    close_advertiser_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    advertiser_configured() {
      if (!this.advertiser.title) return false
      return true
    },
    disabled_button_class() {
      if (this.advertiser_configured) {
        return "advertiser-dialog--button"
      } else {
        return "advertiser-dialog--disabled"
      }
    },
  },
}
</script>
