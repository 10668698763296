import "./final-report.scss";
import Vue from 'vue/dist/vue.esm'
import FinalReportApp from './final-report-app.vue'

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("final-report"))
       .forEach((element) => {
         const final_report = JSON.parse(element.dataset.finalReport)
         const integrations = JSON.parse(element.dataset.integrations)
         const channels     = JSON.parse(element.dataset.channels)
         const can_manage   = JSON.parse(element.dataset.canManage)
         new Vue({
                   data: {
                     final_report,
                     integrations,
                     channels,
                     can_manage,
                   },
                   template: "<FinalReportApp :original_final_report='final_report' :integrations='integrations' :channels='channels' :can_manage='can_manage' />",
                   components: {
                     FinalReportApp: FinalReportApp,
                   }
                 }).$mount(element)
       })
})
