<template>
  <span class="reporting-integration" :title="reporting_integration.code">
    {{ reporting_integration.title }}
    <report-status :status="reporting_integration.status"></report-status>
  </span>
</template>

<script>
import "./reporting-integration.scss";
import ReportStatus from "../report-status/report-status-app"

export default {
  name: "reporting-integration",
  props: {
    integration: {
      required: true,
    },
  },
  components: {
    ReportStatus,
  },
  data() {
    return {
      reporting_integration: this.integration,
    }
  },
  methods: {},
  computed: {},
  watch: {
    "integration"() {
      this.reporting_integration = this.integration
    }
  },
}
</script>
