import "./offer-component.scss";
import Vue from 'vue/dist/vue.esm'
import OffersApp from './offer-component-app.vue'
import ActionCableVue from 'actioncable-vue';

const websocket_protocol = `ws${window.location.protocol === "https:" ? "s" : ""}:`;
Vue.use(ActionCableVue, {
  debug:              true,
  debugLevel:         'info',
  connectionUrl:      `${websocket_protocol}//${window.location.host}/cable`,
  connectImmediately: true
});

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("offer-component"))
    .forEach(element => {
      const offer = JSON.parse(element.dataset.offer);
      const advertisers = JSON.parse(element.dataset.advertisers);
      const channels = JSON.parse(element.dataset.channels);
      new Vue({
        data:       {
          offer:       offer,
          advertisers: advertisers,
          channels:    channels,
        },
        template:   "<OffersApp :original_offer='offer' :advertisers='advertisers' :channels='channels' />",
        components: {
          OffersApp,
        }
      }).$mount(element);
    });
});
