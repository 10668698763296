<template>
  <div class="daily-clicks" id="daily-clicks">
    <analytics-table group_id="date"
                     :show_title="true"
                     :show_clicks="true"
                     :analytics="analytics">
    </analytics-table>
  </div>
</template>

<script>
import "./daily-clicks.scss"
import AnalyticsTable from "../analytics-table/analytics-table-app"
import axios from "axios"

export default {
  name: "daily-clicks",
  props: {
    final_report: {
      required: true,
    }
  },
  components: {
    AnalyticsTable,
  },
  data() {
    return {
      analytics: [],
    }
  },
  methods: {},
  computed: {
    search_params() {
      const search_params = {}

      search_params["group_id"]           = "date"
      search_params["secondary_group_id"] = "campaign_id"
      search_params["campaign_id"]        = this.final_report.campaigns.map(campaign => campaign.id)
      search_params["channel_id"]         = this.final_report.channel_id
      search_params["include_clicks"]     = true

      if (this.final_report.campaigns.length === 1) {
        search_params["start_date"] = this.final_report.campaigns[0].start_date
        search_params["end_date"]   = this.final_report.campaigns[0].end_date
      } else {
        search_params["start_date"] = this.final_report.campaigns.reduce((c1, c2) => c1.start_date < c2.start_date ? c1.start_date : c2.start_date)
        search_params["end_date"]   = this.final_report.campaigns.reduce((c1, c2) => c1.end_date > c2.end_date ? c1.end_date : c2.end_date)
      }

      return search_params
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: `/analytics`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params: this.search_params,
    }).then(response => {
      this.analytics = response.data.analytics
    }).catch(error => {
      console.log("ERROR", error)
      alert("Error loading daily clicks")
    })
  },
}
</script>
