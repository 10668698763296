<template>
  <div class="final-report">
    <register-final-report v-if="can_manage && !final_report.persisted" :integrations="integrations"></register-final-report>

    <template v-else>
      <h1>{{ advertiser_title }}</h1>

      <h2>
        <channel-icon :channel_title="channel_title"></channel-icon>
        {{ channel_title }}
        Final report
      </h2>

      <h3>Campaigns</h3>
      <multiselect v-model="final_report.campaigns"
                   :options="campaigns_by_channel"
                   :close-on-select="true"
                   :clear-on-select="false"
                   :preserveSearch="true"
                   placeholder="Kampagne auswählen"
                   :custom-label="campaign_title"
                   label="title"
                   track-by="id"
                   :allow-empty="true"
                   :multiple="true"
                   :taggable="true"
                   :disabled="true">
      </multiselect>

      <h3>Statistiken</h3>
      <ul id="accordion-container"
          class="final-report--accordion-container"
          data-accordion data-multi-expand="true"
          data-allow-all-closed="true">
        <li class="final-report--item" data-accordion-item>
          <div class="final-report--item--accordion-title" @click="toggle_content(`final-report-option-1-content`)">
            Kumulativer Report
            <a @click.stop.prevent="copy_inner_html" class="final-report--copy-button">
              <i class="fi-clipboard" data-target="cumulative-report"></i>
            </a>
          </div>
          <div id="final-report-option-1-content" class="final-report--item--accordion-content" data-tab-content>
            <cumulative-report :final_report="final_report"></cumulative-report>
          </div>
        </li>
        <li class="final-report--item" data-accordion-item>
          <div class="final-report--item--accordion-title" @click="toggle_content(`final-report-option-2-content`)">
            Daily Impressions
            <a @click.stop.prevent="copy_inner_html" class="final-report--copy-button">
              <i class="fi-clipboard" data-target="daily-impressions"></i>
            </a>
          </div>
          <div id="final-report-option-2-content" class="final-report--item--accordion-content" data-tab-content>
            <daily-impressions :final_report="final_report"></daily-impressions>
          </div>
        </li>
        <li class="final-report--item" data-accordion-item>
          <div class="final-report--item--accordion-title" @click="toggle_content(`final-report-option-3-content`)">
            Daily Klicks
            <a @click.stop.prevent="copy_inner_html" class="final-report--copy-button">
              <i class="fi-clipboard" data-target="daily-clicks"></i>
            </a>
          </div>
          <div id="final-report-option-3-content" class="final-report--item--accordion-content" data-tab-content>
            <daily-clicks :final_report="final_report"></daily-clicks>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import "./final-report.scss";
import RegisterFinalReport from "../register-final-report/register-final-report-app"
import CumulativeReport from "../cumulative-report/cumulative-report-app"
import DailyImpressions from "../daily-impressions/daily-impressions-app"
import DailyClicks from "../daily-clicks/daily-clicks-app"
import ChannelIcon from "../channel-icon/channel-icon-app"
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
import moment from 'moment'
import axios from 'axios'
import $ from 'jquery'

export default {
  name: "final-report",
  props: {
    original_final_report: {
      required: true,
    },
    integrations: {
      required: true,
    },
    channels: {
      required: true,
    },
    can_manage: {
      required: true,
    },
  },
  components: {
    RegisterFinalReport,
    CumulativeReport,
    DailyImpressions,
    DailyClicks,
    ChannelIcon,
    Multiselect,
  },
  data() {
    return {
      final_report: this.original_final_report,
      campaigns: [],
      advertisers: [],
      loading: false,
    }
  },
  methods: {
    campaign_title({title, start_date, end_date, integrations}) {
      let formatted_title = title
      if (start_date && end_date) {
        formatted_title = `${formatted_title} (${this.format_date_time(start_date)} - ${this.format_date_time(end_date)})`
      }
      if (start_date && !end_date) {
        formatted_title = `${formatted_title} (${this.format_date_time(start_date)})`
      }
      formatted_title = `${formatted_title} ${integrations.map(integration => integration.platform_title).join(", ")}`
      return formatted_title
    },
    format_date_time(date_time, format = "L") {
      return moment(date_time).format(format)
    },
    toggle_content(target_id) {
      $("#accordion-container").foundation('toggle', $(`#${target_id}`));
    },
    copy_inner_html(event) {
      event.preventDefault()
      const element = document.getElementById(event.target.dataset.target)
      if (element) {
        navigator.clipboard.writeText(element.innerHTML)
        $(element).fadeOut()
        $(element).fadeIn()
      }
    },
  },
  computed: {
    campaigns_by_channel() {
      return this.campaigns.filter(campaign => {
        return campaign.channel_id === this.final_report.channel_id
      })
    },
    advertiser_title() {
      const advertiser = this.advertisers.find(advertiser => {
        return advertiser.id === this.final_report.advertiser_id
      })
      if (advertiser) return advertiser.title
    },
    channel_title() {
      const channel = this.channels.find(channel => {
        return channel.id === this.final_report.channel_id
      })
      if (channel) return channel.title
    },
  },
  mounted() {
    new Foundation.Accordion($("#accordion-container"))
    axios({
      method: 'get',
      url: `/campaigns.json`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then(response => {
      this.campaigns = response.data
    }).catch(error => {
      console.log("ERROR", error.response.data)
      alert("Error loading campaigns")
    })
    axios({
      method: 'get',
      url: `/advertisers.json`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then(response => {
      this.advertisers = response.data
    }).catch(error => {
      console.log("ERROR", error.response.data)
      alert("Error loading advertisers")
    })
  },
}
</script>
