<template>
  <form @submit.prevent="update_media_plan_title" ref="form" class="update-media-planning-title">
    <div v-if="edit">
      <h3 for="media_plan_title">Edit media plan title</h3>
      <div class="input-group">
        <input type="text"
               v-model="media_planning.title"
               class="input-group-field"
               id="media_plan_title"
               :disabled="is_loading || is_blocked">
        <div class="input-group-button">
          <input type="submit" class="button" value="Update media plan title">
        </div>
      </div>
    </div>

    <div v-else>
      <h1>{{ media_planning.title }}
        <a @click.stop.prevent="copy_media_planning_id" title="Copy media plan ID">
          <i class="fi-clipboard"></i>
        </a>
        <a v-if="can_manage && !is_blocked && !is_loading" @click="edit = true" title="Edit title...">
          <i class="fi-widget"></i>
        </a>
        <a v-if="can_manage && !is_blocked && !is_loading" @click="open_notes_dialog = true"
           :title="media_planning.notes || 'Notes...'">
          <i v-if="media_planning.notes" class="fi-comment-quotes"></i>
          <i v-else class="fi-comment"></i>
        </a>
        <a v-if="can_manage && !is_blocked && !is_loading" @click="open_tasks_dialog = true" title="Tasks...">
          <i class="fi-list"></i>
        </a>
      </h1>
    </div>

    <media-planning-notes-dialog v-if="open_notes_dialog"
                                 :media_planning="media_planning"
                                 :is_blocked="is_loading || is_blocked"
                                 @closed_requested="open_notes_dialog = false">
    </media-planning-notes-dialog>

    <media-planning-tasks-dialog v-if="open_tasks_dialog"
                                 :media_planning="media_planning"
                                 :is_blocked="is_loading || is_blocked"
                                 @closed_requested="open_tasks_dialog = false">
    </media-planning-tasks-dialog>
  </form>
</template>

<script>
import MediaPlanningNotesDialog from "../media-planning-notes-dialog/media-planning-notes-dialog-app"
import MediaPlanningTasksDialog from "../media-planning-tasks-dialog/media-planning-tasks-dialog-app"
import axios from 'axios'

export default {
  name: "update-media-planning-title",
  props: {
    media_planning: {
      required: true,
    },
    can_manage: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    MediaPlanningNotesDialog,
    MediaPlanningTasksDialog,
  },
  data() {
    return {
      edit: false,
      is_loading: false,
      open_notes_dialog: false,
      open_tasks_dialog: false,
    }
  },
  methods: {
    update_media_plan_title() {
      if (!this.can_manage) return
      if (this.is_blocked) return
      if (this.is_loading) return

      this.is_loading = true
      this.$emit("start_loading")

      axios({
        method: 'post',
        url: `/media_plannings/${this.media_planning.id}/update_title`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: {
          title: this.media_planning.title,
        },
      }).then((_response) => {
        this.edit = false
        this.is_loading = false
        this.$emit("stop_loading")
      }).catch(error => {
        console.log("ERROR", error.response.data)
        this.is_loading = false
        alert("Error saving media plan title")
        this.$emit("stop_loading")
      })
    },
    copy_media_planning_id() {
      navigator.clipboard.writeText(this.media_planning.id)
    },
  },
}
</script>

<style scoped>
</style>
