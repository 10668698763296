<template>
  <div class="cumulative-report" id="cumulative-report">
    <analytics-table :show_title="true"
                     :show_impressions="true"
                     :show_clicks="true"
                     :show_click_rate="true"
                     :show_complete="true"
                     :show_complete_rate="true"
                     :show_video_views="true"
                     :show_video_view_rate="true"
                     :show_conversions="true"
                     :show_conversion_rate="true"
                     :show_conversions_value="true"
                     :analytics="analytics"
                     :summary="summary">
    </analytics-table>
  </div>
</template>

<script>
import "./cumulative-report.scss"
import AnalyticsTable from "../analytics-table/analytics-table-app"
import axios from "axios"

export default {
  name: "cumulative-report",
  props: {
    final_report: {
      required: true,
    }
  },
  components: {
    AnalyticsTable,
  },
  data() {
    return {
      analytics: [],
      summary: [],
    }
  },
  methods: {},
  computed: {
    search_params() {
      const search_params = {}

      search_params["group_id"]                = "nil"
      search_params["secondary_group_id"]      = "campaign_id"
      search_params["campaign_id"]             = this.final_report.campaigns.map(campaign => campaign.id)
      search_params["channel_id"]              = this.final_report.channel_id
      search_params["include_impressions"]     = true
      search_params["include_clicks"]          = true
      search_params["include_click_rate"]      = true
      search_params["include_complete"]        = true
      search_params["include_complete_rate"]   = true
      search_params["include_video_views"]     = true
      search_params["include_video_view_rate"] = true

      if (this.final_report.campaigns.length === 1) {
        search_params["start_date"] = this.final_report.campaigns[0].start_date
        search_params["end_date"]   = this.final_report.campaigns[0].end_date
      } else {
        search_params["start_date"] = this.final_report.campaigns.reduce((c1, c2) => c1.start_date < c2.start_date ? c1.start_date : c2.start_date)
        search_params["end_date"]   = this.final_report.campaigns.reduce((c1, c2) => c1.end_date > c2.end_date ? c1.end_date : c2.end_date)
      }

      return search_params
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: `/analytics`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params: this.search_params,
    }).then(response => {
      this.analytics = response.data.analytics
      this.summary = response.data.summary
    }).catch(error => {
      console.log("ERROR", error)
      alert("Error loading analytics")
    })
  },
}
</script>
