<template>
  <div class="media-planning-xv-order">
    <portal to="base-modal">
      <base-modal @closed_requested="close_dialog">
        <form @submit.prevent="save" ref="form">
          <h3>
            <i class="fi-cloud"></i>
            crossvertise Access
          </h3>

          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <label for="media_planning_xv_id">
              Order ID
            </label>
            <input type="text"
                   id="media_planning_xv_id"
                   v-model="xv_order_id"
                   :disabled="is_blocked || is_loading ">
          </fieldset>

          <div>
            <button @click="close_dialog"
                    :disabled="is_blocked || is_loading"
                    class="media-planning-xv-order--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </button>
            <button :disabled="is_blocked || is_loading"
                    class="media-planning-xv-order--button"
                    :class="disabled_button_class">
              <i class="fi-save"></i>
              Speichern
            </button>
            <button v-if="media_planning.xv_order_id"
                    @click.stop.prevent="delete_xv_order_id"
                    :disabled="is_blocked || is_loading"
                    :class="disabled_button_class"
                    class="media-planning-xv-order--button--alert">
              <i class="fi-alert"></i>
              Löschen
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./media-planning-xv-order.scss"
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios"

export default {
  name: "media-planning-xv-order",
  props: {
    media_planning: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      xv_order_id: this.media_planning.xv_order_id,
      is_loading: false,
      errors: null,
    }
  },
  methods: {
    save() {
      if (this.is_loading) return
      if (this.is_blocked) return

      this.is_loading = true
      this.errors = null

      axios({
        method: 'post',
        url: `/media_plannings/${this.media_planning.id}/assign_xv_order_id`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: {
          xv_order_id: this.xv_order_id,
        },
      }).then(_response => {
        this.is_loading = false
        this.close_dialog()
      }).catch(error => {
        this.is_loading = false
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data)
        alert("Error saving crossvertise order ID")
      })
    },
    delete_xv_order_id() {
      let confirmation = confirm("Do you really want to delete the crossvertise order ID?")
      if (!confirmation) return

      this.xv_order_id = null
      this.save()
    },
    close_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    disabled_button_class() {
      if (this.is_loading) return "media-planning-xv-order--button--disabled"
      return "media-planning-xv-order--button"
    },
  },
}
</script>
