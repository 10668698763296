import { render, staticRenderFns } from "./percent-rate.vue?vue&type=template&id=5a067962&scoped=true&"
import script from "./percent-rate.vue?vue&type=script&lang=js&"
export * from "./percent-rate.vue?vue&type=script&lang=js&"
import style0 from "./percent-rate.vue?vue&type=style&index=0&id=5a067962&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a067962",
  null
  
)

export default component.exports