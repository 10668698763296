<template>
  <div class="ad-group-metrics">
    <h5>Top SEA Anzeigengruppen</h5>

    <div v-if="loading">Loading...</div>

    <div v-else-if="ad_group_metrics.length">
      <table>
        <thead class="ad-group-metrics--thead">
        <tr>
          <th class="keyword-metrics--left-aligned">Campaign</th>
          <th class="keyword-metrics--left-aligned">Ad group</th>
          <th><abbr title="Conversions">CONVS</abbr></th>
          <th><abbr title="Conversion rate">CVR</abbr></th>
          <th><abbr title="Cost per conversion">CPO</abbr></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="ad_group_metric in ad_group_metrics">
          <td class="keyword-metrics--left-aligned">{{ ad_group_metric.campaign_name }}</td>
          <td class="keyword-metrics--left-aligned">{{ ad_group_metric.ad_group_name }}</td>
          <td>
            <vue-numeric v-if="typeof ad_group_metric.conversions === 'number'"
                         :value=ad_group_metric.conversions
                         separator="."
                         v-bind:precision=2
                         v-bind:read-only=true>
            </vue-numeric>
          </td>
          <td>
            <percent-rate :rate="ad_group_metric.conversion_rate"></percent-rate>
          </td>
          <td>
            <vue-numeric
                v-if="typeof ad_group_metric.cpo === 'number' && ad_group_metric.cpo !== Infinity"
                v-model="ad_group_metric.cpo"
                separator="."
                v-bind:precision=2
                v-bind:read-only=true
                currency="€"
                currency-symbol-position="suffix">
            </vue-numeric>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-else>No reports yet</div>
  </div>
</template>

<script>
import "./ad-group-metrics.scss"
import axios from "axios";
import PercentRate from "../percent-rate/percent-rate.vue";
import VueNumeric from 'vue-numeric'

export default {
  name:       "ad-group-metrics",
  props:      {
    campaign: {
      type:     Object,
      required: true,
    },
  },
  components: {
    PercentRate,
    VueNumeric,
  },
  data() {
    return {
      ad_group_metrics: [],
      loading:          false,
    }
  },
  methods:  {},
  computed: {},
  mounted() {
    if (this.campaign.include_ad_group_metrics) {
      this.loading = true
      axios({
        method: 'get',
        url: `/campaigns/${this.campaign.id}/ad_group_metrics.json`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then(response => {
        this.loading = false
        this.ad_group_metrics = response.data
      }).catch(error => {
        this.loading = false
        console.log("ERROR", error.response.data)
        alert("Error loading ad group metrics")
      })
    }
  },
}
</script>
