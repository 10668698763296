<template>
  <span v-if="typeof campaign.conversion_rate === 'number' && ['SEA'].includes(campaign.channel_title)"
        class="campaign-conversion-rate">
        <abbr title="Conversion rate">CVR</abbr>
        <percent-rate :rate="campaign.conversion_rate" :min_rate="min_rate(campaign.channel_title, 'conversion')">
        </percent-rate>
  </span>
</template>

<script>
import "./campaign-conversion-rate.scss";
import PercentRate from "../percent-rate/percent-rate.vue"
import channel_rates from "../../lib/channel-rates";

export default {
  name:       "campaign-conversion-rate",
  props:      {
    campaign: {
      required: true,
    },
    channels: {
      required: true,
    },
  },
  components: {
    PercentRate,
  },
  data() {
    return {}
  },
  methods:  {
    min_rate(channel_title, rate) {
      return channel_rates(this.channels, channel_title, rate)
    },
  },
  computed: {},
}
</script>
