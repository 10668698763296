<template>
  <div class="customer-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_customer_dialog">
        <h3 v-if="customer.persisted"> Update customer</h3>
        <h3 v-else>New Customer</h3>

        <form @submit.prevent="save_customer" ref="form">
          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <label v-if="customer.persisted">
              ID:
              <input type="text"
                     v-model="customer.id"
                     :disabled="true">
            </label>
            <label>
              Titel:
              <input type="text"
                     v-model="customer.title"
                     placeholder="Titel">
            </label>
            <label v-if="customer_types.length">
              Customer type:
              <select v-model="customer.type_id" :disabled="loading">
                <option v-for="customer_type in customer_types"
                        :value=customer_type.id
                        :selected="customer_type.id === customer.type_id">
                  {{ customer_type.title }}
                </option>
              </select>
            </label>
          </fieldset>

          <div>
            <a v-if="customer.persisted"
               @click.stop.prevent="delete_customer"
               :class="disabled_button_class"
               class="customer-dialog--button--alert">
              <i class="fi-alert"></i>
              Löschen ...
            </a>
            <a @click="close_customer_dialog" class="customer-dialog--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </a>
            <button v-if="customer_configured"
                    :class="disabled_button_class">
              <i class="fi-save"></i>
              <span v-if="customer.persisted">Update customer</span>
              <span v-else>Add customer</span>
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./customer-dialog.scss"
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios"

export default {
  name: "customer-dialog",
  props: {
    customer: {
      required: false,
      default() {
        return {
          id: null,
          title: null,
          type_id: null,
          persisted: false,
        }
      },
    },
    customer_types: {
      required: true,
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      errors: null,
    }
  },
  methods: {
    save_customer() {
      if (!this.customer_configured) return
      if (this.loading) return

      this.loading = true
      this.errors = null

      if (this.customer.persisted) {
        axios({
          method: 'post',
          url: `/customers/${this.customer.id}/update`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.customer.id,
            title: this.customer.title,
            type_id: this.customer.type_id,
          },
        }).then((_response) => {
          this.loading = false
          this.close_customer_dialog()
        }).catch(error => {
          this.loading = false
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving customer")
        })
      } else {
        axios({
          method: 'post',
          url: `/customers/register`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.customer.id,
            title: this.customer.title,
            type_id: this.customer.type_id,
          },
        }).then((_response) => {
          this.loading = false
          this.close_customer_dialog()
        }).catch(error => {
          this.loading = false
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving customer")
        })
      }
    },
    delete_customer() {
      let confirmation = confirm("Do you really want to delete the customer?")
      if (!confirmation) return
      axios({
        method: 'delete',
        url: `/customers/${this.customer.id}/delete`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then((_response) => {
        this.loading = false
        this.close_customer_dialog()
      }).catch(error => {
        this.loading = false
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data)
        alert("Error deleting customer")
      })
    },
    close_customer_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    customer_configured() {
      if (!this.customer.title) return false
      return true
    },
    disabled_button_class() {
      if (this.customer_configured) {
        return "customer-dialog--button"
      } else {
        return "customer-dialog--disabled"
      }
    },
  },
}
</script>
