<template>
  <div class="analytics-info">
    <portal to="base-modal">
      <base-modal @closed_requested="close_dialog">
        <form @submit.prevent="save" ref="form">
          <h3>
            <i class="info"></i> Analytics Info
          </h3>

          <fieldset>
            <label for="analytics_info">
              Text
            </label>
            <input type="text"
                   id="analytics_info"
                   v-model="text"
                   :disabled="is_loading || is_blocked">
          </fieldset>

          <div>
            <button class="analytics-info--button">
              <i class="fi-save"></i>
              Speichern
            </button>

            <button @click.stop="close_dialog"
                    :disabled="is_loading"
                    class="analytics-info--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./analytics-info.scss"
import BaseModal from "../base-modal/base-modal-app.vue";
import axios from "axios";

export default {
  name:       "analytics-info",
  props:      {
    text: {
      required: true,
    },
    on_update: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    BaseModal
  },
  data() {
    return {
      is_loading: false,
    }
  },
  methods:  {
    save() {
      if (this.is_loading) return
      if (this.is_blocked) return

      this.is_loading = true
      this.errors = null

      axios({
        method: 'post',
        url: `/analytics_info`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: {
          text: this.text,
        },
      }).then(_response => {
        this.is_loading = false
        this.on_update(this.text)
        this.close_dialog()
      }).catch(error => {
        this.is_loading = false
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data)
        alert("Error saving analytics info")
      })
    },
    close_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {},
}
</script>
