import "./advertisers.scss";
import Vue from 'vue/dist/vue.esm'
import AdvertisersApp from './advertisers-app.vue'
import ActionCableVue from 'actioncable-vue';

const websocket_protocol = `ws${window.location.protocol === "https:" ? "s" : ""}:`;
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'info',
  connectionUrl: `${websocket_protocol}//${window.location.host}/cable`,
  connectImmediately: true
});

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("advertisers"))
       .forEach(element => {
         const advertisers = JSON.parse(element.dataset.advertisers);
         new Vue({
                   data: {
                     advertisers: advertisers,
                   },
                   template: "<AdvertisersApp :original_advertisers='advertisers' />",
                   components: {
                     AdvertisersApp,
                   }
                 }).$mount(element);
       });
});
