import "./outbrain-marketers.scss";
import Vue from 'vue/dist/vue.esm'
import OutbrainMarketersApp from './outbrain-marketers-app.vue'
import ActionCableVue from 'actioncable-vue';

const websocket_protocol = `ws${window.location.protocol === "https:" ? "s" : ""}:`;
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'info',
  connectionUrl: `${websocket_protocol}//${window.location.host}/cable`,
  connectImmediately: true
});

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("outbrain-marketers"))
       .forEach((element) => {
         const outbrain_marketers = JSON.parse(element.dataset.outbrainMarketers);
         new Vue({
                   data: {
                     outbrain_marketers: outbrain_marketers,
                   },
                   template: "<OutbrainMarketersApp :original_outbrain_marketers='outbrain_marketers' />",
                   components: {
                     OutbrainMarketersApp,
                   }
                 }).$mount(element);
       });
});
