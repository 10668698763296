<template>
  <form autocomplete="off">
    <div v-if="goal.rule.locationRules.length">
      <h3>{{ goal.rule.locationRules.length }} location rules:</h3>
      <table>
        <thead>
        <tr>
          <th>Location ID</th>
          <th>Location type</th>
          <th>Location name</th>
          <th>Access</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="location_rule in goal.rule.locationRules">
          <td>{{ location_rule.locationId }}</td>
          <td>{{ location_rule.locationType }}</td>
          <td>{{ location_rule.locationName }}</td>
          <td>{{ location_rule.access }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <h3>Location rules</h3>
      <p>No location rules present</p>
    </template>

    <fieldset class="invidi-goal-form--container">
      <label :for="`city_name_${goal.id}`">
        Search city for locality search
      </label>
      <input :id="`city_name_${goal.id}`" type="text" placeholder="e.g. cologne" v-model="search_phrase"
             @input="on_change">
      <ul v-show="is_open" class="invidi-goal-form--autocomplete-locations">
        <li class="loading" v-if="is_loading">
          Searching locations...
        </li>
        <li v-else v-for="(location, i) in locations" :key="i" @click="set_location(location)"
            class="invidi-goal-form--autocomplete-location">
          {{ location.city_name }}
        </li>
      </ul>

      <div v-if="location">
        <label :for="`radius_${location.id}`">
          Radius around: {{ location.city_name }}
        </label>
        <div class="input-group">
          <input :id="`radius_${location.id}`"
                 v-model="radius"
                 type="text"
                 placeholder="e.g. 50"
                 class="input-group-field">
          <div class="input-group-button">
            <button @click.prevent="search_via_locality" class="float-right invidi-goal-form--button">
              Suche locations
            </button>
          </div>
        </div>
        <div v-if="target_locations.length">
          <div class="float-right">
            <button v-if="target_locations.length"
                    @click.prevent="update_goal_locations('add')"
                    class="invidi-goal-form--button"
                    :class="disabled_button_class"
                    :disabled="is_loading">
              Locations zu Kampagne / Goal hinzufügen
            </button>
            <br>
            <button v-if="target_locations.length"
                    @click.prevent="update_goal_locations('remove')"
                    class="invidi-goal-form--button"
                    :class="disabled_button_class"
                    :disabled="is_loading">
              Locations von Kampagne / Goal entfernen
            </button>
          </div>
          <h4>{{ target_locations.length }} locations found</h4>
          <ul>
            <li v-for="tl in target_locations">
              {{ tl.city_name }}
              (
              <vue-numeric v-bind:precision=2 output-type="String" currency="km" currency-symbol-position="suffix"
                           v-bind:read-only=true v-model="tl.geo_near_distance"></vue-numeric>
              )
            </li>
          </ul>
        </div>
      </div>
    </fieldset>

    <div v-if="goal.rule.tagAndPartnerRules.length">
      <h3>{{ goal.rule.tagAndPartnerRules.length }} tag and partner rules:</h3>
      <table>
        <thead>
        <tr>
          <th>Resource type</th>
          <th>Rule type</th>
          <th>Tag</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="tag_and_partner_rule in goal.rule.tagAndPartnerRules">
          <td>{{ tag_and_partner_rule.resourceType }}</td>
          <td>{{ tag_and_partner_rule.ruleType }}</td>
          <td>{{ tag_and_partner_rule.tag }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <h3>Tag and partner rules</h3>
      <p>No tag and partner rules present</p>
    </template>

    <div v-if="goal.rule.frequencyRules.length">
      <h3>{{ goal.rule.frequencyRules.length }} frequency rules:</h3>
      <table>
        <thead>
        <tr>
          <th>Impressions</th>
          <th>Time unit</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="frequency_rule in goal.rule.frequencyRules">
          <td>{{ frequency_rule.impressions }}</td>
          <td>{{ frequency_rule.timeUnit }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <h3>Frequency rules</h3>
      <p>No frequency rules present</p>
    </template>

    <div v-if="goal.rule.timeRules.length">
      <h3>{{ goal.rule.timeRules.length }} time rules:</h3>
      <table>
        <thead>
        <tr>
          <th>Active</th>
          <th>Days</th>
          <th>From hour</th>
          <th>To hour</th>
          <th>From minute</th>
          <th>To minute</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="time_rule in goal.rule.timeRules">
          <td>{{ time_rule.active }}</td>
          <td>{{ time_rule.days.join(", ") }}</td>
          <td>{{ time_rule.fromHour }}</td>
          <td>{{ time_rule.toHour }}</td>
          <td>{{ time_rule.fromMinute }}</td>
          <td>{{ time_rule.toMinute }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <h3>Time rules</h3>
      <p>No time rules present</p>
    </template>

    <div v-if="goal.rule.tagAndPartnerRules.length">
      <h3>{{ goal.rule.tagAndPartnerRules.length }} tag and partner rules:</h3>
      <table>
        <thead>
        <tr>
          <th>Resource type</th>
          <th>Rule type</th>
          <th>Tag</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="tag_and_partner_rule in goal.rule.tagAndPartnerRules">
          <td>{{ tag_and_partner_rule.resourceType }}</td>
          <td>{{ tag_and_partner_rule.ruleType }}</td>
          <td>{{ tag_and_partner_rule.tag }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <p v-else>No tag and partner rules present</p>

    <div v-if="goal.rule.categoryRules.length">
      <h3>{{ goal.rule.categoryRules.length }} category rules:</h3>
      <table>
        <thead>
        <tr>
          <th>Category ID</th>
          <th>Category name</th>
          <th>Rule type</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="category_rule in goal.rule.categoryRules">
          <td>{{ category_rule.categoryId }}</td>
          <td>{{ category_rule.categoryName }}</td>
          <td>{{ category_rule.ruleType }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <h3>Category rules</h3>
      <p>No category rules present</p>
    </template>

    <div v-if="goal.rule.ipRules.length">
      <h3>{{ goal.rule.ipRules.length }} IP rules:</h3>
      <table>
        <thead>
        <tr>
          <th>IP matcher</th>
          <th>Access</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="ip_rule in goal.rule.ipRules">
          <td>{{ ip_rule.ipMatcher }}</td>
          <td>{{ ip_rule.access }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <h3>IP rules</h3>
      <p>No IP rules present</p>
    </template>

    <div v-if="goal.rule.userAgentRules.length">
      <h3>{{ goal.rule.userAgentRules.length }} user agent rules:</h3>
      <table>
        <thead>
        <tr>
          <th>Access</th>
          <th>Browsers</th>
          <th>Operating_systems</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user_agent_rule in goal.rule.userAgentRules">
          <td>{{ user_agent_rule.access }}</td>
          <td>{{ user_agent_rule.browsers.join(", ") }}</td>
          <td>{{ user_agent_rule.operatingSystems.join(", ") }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <h3>User agent rules</h3>
      <p>No user agent rules present</p>
    </template>

    <h3>Ignore parent rules</h3>
    <table>
      <thead>
      <tr>
        <th>Location</th>
        <th>Tag and partners</th>
        <th>Content</th>
        <th>Time</th>
        <th>Frequency</th>
        <th>IP</th>
        <th>User agent</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ goal.rule.ignoreParentLocationRules }}</td>
        <td>{{ goal.rule.ignoreParentTagRules }}</td>
        <td>{{ goal.rule.ignoreParentContentRules }}</td>
        <td>{{ goal.rule.ignoreParentTimeRules }}</td>
        <td>{{ goal.rule.ignoreParentFrequencyRules }}</td>
        <td>{{ goal.rule.ignoreParentIpRules }}</td>
        <td>{{ goal.rule.ignoreParentUserAgentRules }}</td>
      </tr>
      </tbody>
    </table>

    <h3>Account custom parameter rules</h3>
    <pre v-if="goal.rule.userAgentRules.length">{{ goal.rule.accountCustomParameterRules }}</pre>
    <p v-else>No account custom parameter rules present</p>

    <h3>Audience targeting</h3>
    <pre v-if="Object.keys(goal.rule.audienceTargeting).length">{{ goal.rule.audienceTargeting }}</pre>
    <p v-else>No audience targeting present</p>

    <h3>Parent ID</h3>
    <p>{{ goal.rule.parentId }}</p>
  </form>
</template>

<script>
import VueNumeric from 'vue-numeric';
import axios from "axios";

export default {
  name: "locations-form",
  props: [
    "original_goal",
  ],
  components: {
    VueNumeric
  },
  data() {
    return {
      goal: this.original_goal,
      search_phrase: "",
      locations: [],
      is_open: false,
      is_loading: false,
      cancel_token_source: null,
      location: null,
      radius: 25,
      target_locations: [],
    }
  },
  methods: {
    on_change() {
      this.is_open    = true;
      this.is_loading = true;

      if (this.cancel_token_source) {
        this.cancel_token_source.cancel('New search replaces the existing to avoid race conditions.');
      }
      this.cancel_token_source = axios.CancelToken.source();

      axios({
              method: 'get',
              url: `/locations`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              params: {search_phrase: this.search_phrase},
              cancelToken: this.cancel_token_source.token,
            })
          .then((response) => {
            this.is_loading = false;
            this.locations  = response.data.map((location) => {
              return {
                id: location._id.$oid,
                city_name: location.city_name,
              }
            })
          })
          .finally(() => {
            this.cancel_token_source = null
          })
          .catch(error => {
            console.log("ERROR", error);
            this.is_loading = false;
          });
    },
    set_location(location) {
      this.location         = location;
      this.is_open          = false;
      this.target_locations = [];
    },
    search_via_locality() {
      if (this.cancel_token_source) {
        this.cancel_token_source.cancel('New search replaces the existing to avoid race conditions.');
      }
      this.cancel_token_source = axios.CancelToken.source();
      axios({
              method: 'get',
              url: `/locations`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              params: {center: this.location.id, radius: this.radius},
              cancelToken: this.cancel_token_source.token,
            })
          .then((response) => {
            this.cancel_token_source = null
            this.target_locations = response.data;
          })
          .catch(error => {
            this.cancel_token_source = null
            console.log("ERROR", error.response.data);
          });
    },
    update_goal_locations(operation) {
      this.is_loading = true;
      axios({
              method: 'put',
              url: `/goals/${this.goal.id}.json`,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
              },
              data: {
                goal: this.goal,
                locations: this.target_locations,
                operation: operation
              },
            })
          .then((_response) => {
            this.is_loading = false
            location.reload()
          })
          .catch(error => {
            this.is_loading = false
            console.log("ERROR", error.response.data)
          })
    }
  },
  computed: {
    disabled_button_class() {
      if (this.is_loading) {
        return "invidi-goal-form--button--disabled"
      }
    },
  },
}
</script>

<style scoped>
</style>
