<template>
  <tr class="reporting-row">
    <td class="reporting-row--scheduled_at">
      {{ format_date_time(reporting.scheduled_at, "DD.MM.YYYY HH:mm:ss") }}
    </td>
    <td class="reporting-row--timeframe">
      {{ format_date_time(reporting.start_date, "L") }}-{{ format_date_time(reporting.end_date, "L") }}
    </td>
    <td>
      <reporting-integration v-for="integration in reporting.integrations"
                             :integration="integration"
                             :key="integration.id"></reporting-integration>
    </td>
  </tr>
</template>

<script>
import "./reporting-row.scss";
import moment from "moment"
moment.locale("de")
import ReportingIntegration from "../reporting-integration/reporting-integration-app"

export default {
  name: "reporting-row",
  props: {
    media_planning: {
      required: false,
    },
    reporting: {
      required: true,
    },
  },
  components: {
    ReportingIntegration: ReportingIntegration,
  },
  data() {
    return {}
  },
  methods: {
    format_date_time(date_time, format = "lll") {
      return moment(date_time).locale("de").format(format)
    },
  },
  computed: {},
}
</script>
