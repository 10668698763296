<template>
  <fieldset class="media-planning">
    <div class="media-planning--container">
      <register-media-planning v-if="!media_planning.id" :can_manage="can_manage"></register-media-planning>
      <update-media-planning-title v-else
                                   :media_planning="media_planning"
                                   :can_manage="can_manage"
                                   :is_blocked="is_loading"
                                   @start_loading="is_loading=true"
                                   @stop_loading="is_loading=false">
      </update-media-planning-title>

      <div v-if="media_planning.id" class="media-planning--grid9">
        <div class="media-planning--cell media-planning--cell--margin-top">
          <select @change="assign_customer($event)" :disabled="!can_manage || is_loading">
            <option value="">w/o customer</option>
            <option v-for="customer in customers"
                    :value=customer.id
                    :selected="customer.id === media_planning.customer_id">
              {{ customer.title }}
            </option>
          </select>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <select @change="assign_advertiser($event)" :disabled="!can_manage || is_loading">
            <option value="">w/o advertiser</option>
            <option v-for="advertiser in advertisers"
                    :value=advertiser.id
                    :selected="advertiser.id === media_planning.advertiser_id">
              {{ advertiser.title }}
            </option>
          </select>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <select @change="assign_manager($event)" :disabled="!can_manage || is_loading">
            <option value="">w/o manager</option>
            <option v-for="manager in managers"
                    :value=manager.id
                    :selected="manager.id === media_planning.manager_id">
              {{ manager.email }}
            </option>
          </select>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <select @change="assign_partner_manager($event)" :disabled="!can_manage || is_loading">
            <option value="">w/o partner manager</option>
            <option v-for="manager in managers"
                    :value=manager.id
                    :selected="manager.id === media_planning.partner_manager_id">
              {{ manager.email }}
            </option>
          </select>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <select @change="assign_substitute_manager($event)" :disabled="!can_manage || is_loading">
            <option value="">w/o substitute manager</option>
            <option v-for="manager in managers"
                    :value=manager.id
                    :selected="manager.id === media_planning.substitute_manager_id">
              {{ manager.email }}
            </option>
          </select>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <toggle-button :state="media_planning.live"
                         :disabled="!can_manage || is_loading"
                         text="Live campaign?"
                         @toggled="toggle_live">
          </toggle-button>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <toggle-button :state="media_planning.weekly_reporting"
                         :disabled="!can_manage || is_loading"
                         text="Weekly reporting?"
                         @toggled="toggle_weekly_reporting">
          </toggle-button>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <toggle-button :state="media_planning.detailed_final_reporting"
                         :disabled="!can_manage || is_loading"
                         text="Detailed final reporting?"
                         @toggled="toggle_detailed_final_reporting">
          </toggle-button>
        </div>

        <div class="media-planning--cell media-planning--cell--margin-top">
          <span class="media-plannings--turnover-group">
            {{ turnover_group_title(media_planning) }}
          </span>
          <div v-if="media_planning.turnover || media_planning.turnover === 0.0">
            <i class="fa-solid fi-euro" title="Turnover"></i>
            <vue-numeric v-model="media_planning.turnover"
                         separator="."
                         v-bind:precision=2
                         v-bind:read-only=true
                         currency="Euro"
                         currency-symbol-position="suffix">
            </vue-numeric>
          </div>
        </div>
      </div>

      <media-planning-campaigns :media_planning="media_planning"
                                :integrations="integrations"
                                :channels="channels"
                                :google_ads_customers="google_ads_customers"
                                :active_agent_accounts="active_agent_accounts"
                                :outbrain_marketers="outbrain_marketers"
                                :turnover_groups="turnover_groups"
                                :can_manage="can_manage"
                                :is_blocked="is_loading"
                                @start_loading="this.is_loading = true">
      </media-planning-campaigns>

      <reportings :media_planning="media_planning"></reportings>

      <button v-if="media_planning.id && can_manage"
              @click.stop.prevent="delete_media_planning"
              :class="disabled_button_class"
              class="media-planning--button--alert"
              :disabled="is_loading">
        <i class="fi-alert"></i>
        Delete media plan ...
      </button>

      <template v-if="can_manage">
        <pre v-if="debug">{{ media_planning }}</pre>
        <a v-else @click="debug = !debug" class="media-planning--button">
          <i class="fi-info"></i>
          Debug!
        </a>
      </template>

      <media-planning-time-trackings :media_planning="media_planning"></media-planning-time-trackings>
    </div>

    <portal-target name="base-modal"></portal-target>
  </fieldset>
</template>

<script>
import axios from 'axios';
import VueNumeric from 'vue-numeric';
import RegisterMediaPlanning from "../register-media-planning/register-media-planning";
import UpdateMediaPlanningTitle from "../update-media-planning-title/update-media-planning-title-app";
import ReportStatus from "../report-status/report-status-app";
import ToggleButton from "../toggle-button/toggle-button";
import MediaPlanningCampaigns from "../media-planning-campaigns/media-planning-campaigns-app";
import Reportings from "../reportings/reportings-app";
import MediaPlanningTimeTrackings from "../media-planning-time-trackings/media-planning-time-trackings-app.vue";

const _ = require('lodash')

export default {
  name: "media-planning-app",
  components: {
    MediaPlanningTimeTrackings,
    RegisterMediaPlanning,
    UpdateMediaPlanningTitle,
    ReportStatus,
    ToggleButton,
    MediaPlanningCampaigns,
    Reportings,
    VueNumeric,
  },
  props: {
    original_media_planning: {
      required: true,
    },
    customers: {
      required: true,
    },
    channels: {
      required: true,
    },
    integrations: {
      required: true,
    },
    turnover_groups: {
      required: true,
    },
    google_ads_customers: {
      required: true,
    },
    active_agent_accounts: {
      required: true,
    },
    outbrain_marketers: {
      required: true,
    },
    can_manage: {
      required: true,
    },
  },
  data() {
    return {
      media_planning: this.original_media_planning,
      users: [],
      advertisers: [],
      is_loading: false,
      debug: false,
    }
  },
  channels: {
    MediaPlanningUpdatedChannel: {
      connected() {},
      rejected() {},
      received(media_planning_json) {
        console.log("MediaPlanningUpdatedChannel received");
        const received_media_planning = JSON.parse(media_planning_json);

        if (received_media_planning.campaigns) {
          Object.assign(this.media_planning, _.omit(received_media_planning, ["campaigns"]))

          this.media_planning.campaigns = received_media_planning.campaigns.map(received_campaign => {
            const staying_campaign = this.media_planning.campaigns.find(old_campaign => old_campaign.id === received_campaign.id);
            if (staying_campaign) {
              return Object.assign(staying_campaign, received_campaign)
            } else {
              return received_campaign
            }
          })
        } else {
          Object.assign(this.media_planning, received_media_planning)
        }

        console.log("MediaPlanning updated", received_media_planning)
      },
      disconnected() {},
    },
    MediaPlanningDeletedChannel: {
      connected() {
      },
      rejected() {
      },
      received(_media_planning_json) {
        console.log("MediaPlanningDeletedChannel received");
        window.location.href = "/media_plannings"
      },
      disconnected() {
      },
    },
  },
  methods: {
    turnover_group_title(media_planning) {
      const turnover_group = this.turnover_groups.find(turnover_group => turnover_group.id === media_planning.turnover_group_id)
      if (turnover_group) return turnover_group.title
    },
    toggle_live(live_state) {
      if (!this.can_manage) return
      if (this.is_loading) return
      this.is_loading = true
      axios({
              method: 'post',
              url: `/media_plannings/${this.media_planning.id}/update_live`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {live: live_state},
            })
          .then(() => this.is_loading = false)
    },
    toggle_weekly_reporting(weekly_reporting) {
      if (!this.can_manage) return
      if (this.is_loading) return
      this.is_loading = true
      axios({
              method: 'post',
              url: `/media_plannings/${this.media_planning.id}/update_weekly_reporting`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {weekly_reporting: weekly_reporting},
            })
          .then(() => this.is_loading = false)
    },
    toggle_detailed_final_reporting(detailed_final_reporting) {
      if (!this.can_manage) return
      if (this.is_loading) return
      this.is_loading = true
      axios({
              method: 'post',
              url: `/media_plannings/${this.media_planning.id}/update_detailed_final_reporting`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {detailed_final_reporting: detailed_final_reporting},
            })
          .then(() => this.is_loading = false)
    },
    assign_customer(event) {
      if (!this.can_manage) return
      const customer_id = event.target.value;
      this.is_loading   = true
      if (customer_id) {
        axios({
                method: 'post',
                url: `/media_plannings/${this.media_planning.id}/assign_customer`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                data: {customer_id: customer_id},
              })
            .then(() => this.is_loading = false)
      } else {
        axios({
                method: 'post',
                url: `/media_plannings/${this.media_planning.id}/unassign_customer`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
              })
            .then(() => this.is_loading = false)
      }
    },
    assign_advertiser(event) {
      if (!this.can_manage) return
      const advertiser_id = event.target.value;
      this.is_loading     = true
      if (advertiser_id) {
        axios({
                method: 'post',
                url: `/media_plannings/${this.media_planning.id}/assign_advertiser`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                data: {advertiser_id: advertiser_id},
              })
            .then(() => this.is_loading = false)
      } else {
        axios({
                method: 'post',
                url: `/media_plannings/${this.media_planning.id}/unassign_advertiser`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
              })
            .then(() => this.is_loading = false)
      }
    },
    assign_manager(event) {
      if (!this.can_manage) return
      const manager_id = event.target.value;
      this.is_loading  = true
      if (manager_id) {
        axios({
                method: 'post',
                url: `/media_plannings/${this.media_planning.id}/assign_manager`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                data: {manager_id: manager_id},
              })
            .then(() => this.is_loading = false)
      } else {
        axios({
                method: 'post',
                url: `/media_plannings/${this.media_planning.id}/unassign_manager`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
              })
            .then(() => this.is_loading = false)
      }
    },
    assign_partner_manager(event) {
      if (!this.can_manage) return
      const manager_id = event.target.value;
      this.is_loading = true
      if (manager_id) {
        axios({
          method: 'post',
          url: `/media_plannings/${this.media_planning.id}/assign_partner_manager`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: { manager_id: manager_id },
        }).then(() => this.is_loading = false)
            .catch(error => {
              this.is_loading = false
              console.log("ERROR", error.response.data)
              alert("Error assigning partner manager")
            })
      } else {
        axios({
          method: 'post',
          url: `/media_plannings/${this.media_planning.id}/unassign_partner_manager`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        }).then(() => this.is_loading = false)
            .catch(error => {
              this.is_loading = false
              console.log("ERROR", error.response.data)
              alert("Error assigning partner manager")
            })
      }
    },
    assign_substitute_manager(event) {
      if (!this.can_manage) return
      const manager_id = event.target.value;
      this.is_loading = true
      if (manager_id) {
        axios({
          method: 'post',
          url: `/media_plannings/${this.media_planning.id}/assign_substitute_manager`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: { manager_id: manager_id },
        }).then(() => this.is_loading = false)
            .catch(error => {
              this.is_loading = false
              console.log("ERROR", error.response.data)
              alert("Error assigning substitute manager")
            });
      } else {
        axios({
          method: 'post',
          url: `/media_plannings/${this.media_planning.id}/unassign_substitute_manager`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        }).then(() => this.is_loading = false)
            .catch(error => {
              this.is_loading = false
              console.log("ERROR", error.response.data)
              alert("Error unassigning substitute manager")
            });
      }
    },
    delete_media_planning() {
      if (!this.can_manage) return
      if (this.is_loading) return
      let confirmation = confirm("Do you really want to delete the media plan? This also includes all campaigns and reports\n.");
      if (!confirmation) return
      this.is_loading = true
      axios({
        method: 'delete',
        url: `/media_plannings/${this.media_planning.id}/delete`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then((response) => {
        window.location.href = response.headers.location
      }).catch(error => {
        this.is_loading = false
        console.log("ERROR", error.response.data)
        alert("Error deleting media plan")
      });
    },
    unsubscribe() {
      console.log("MediaPlanningUpdatedChannel unsubscribe");
      this.$cable.unsubscribe('MediaPlanningUpdatedChannel');
    },
  },
  computed: {
    disabled_button_class() {
      if (this.is_loading) {
        return "media-planning--button--disabled"
      } else {
        return "media-planning--button"
      }
    },
    managers() {
      return this.users.filter(user => user.role === "manager")
    },
  },
  watch: {},
  mounted() {
    if (!this.media_planning.id) return

    axios({
      method: 'get',
      url: `/advertisers.json`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then(response => {
      this.advertisers = response.data
    }).catch(error => {
      console.log("ERROR", error.response.data)
      alert("Error loading advertisers")
    })

    axios({
      method: 'get',
      url: `/users.json`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then(response => {
      this.users = response.data
    }).catch(error => {
      console.log("ERROR", error.response.data)
      alert("Error loading users")
    })

    this.$cable.subscribe({
      channel: 'MediaPlanningUpdatedChannel',
      media_planning_id: this.media_planning.id
    });
    this.$cable.subscribe({
      channel: 'MediaPlanningDeletedChannel',
      media_planning_id: this.media_planning.id
    });
  },
}
</script>
