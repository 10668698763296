<template>
  <div v-if="media_planning.id" class="reportings">
    <h3>Reportings</h3>
    <template v-if="displayable_reportings.length">
      <table>
        <thead>
        <th>Start time</th>
        <th>Period</th>
        <th>Integrations</th>
        </thead>
        <tbody>
        <reporting-row v-for="reporting in displayable_reportings"
                       :media_planning="media_planning"
                       :reporting="reporting"
                       :key="reporting.id">
        </reporting-row>
        </tbody>
      </table>
    </template>

    <p v-else>Keine Reportings vorhanden</p>
  </div>
</template>

<script>
import "./reportings.scss";
import ReportingRow from "../reporting-row/reporting-row-app"
import ScheduleCampaignReporting from "../schedule-campaign-reporting/schedule-campaign-reporting-app"

export default {
  name: "reportings",
  props: {
    media_planning: {
      required: true,
    },
  },
  components: {
    ReportingRow,
    ScheduleCampaignReporting,
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    displayable_reportings() {
      return this.media_planning.reportings.sort((a, b) => {
        return (a.scheduled_at < b.scheduled_at) ? 1 : -1
      })
                 .slice(0, this.media_planning.campaigns.length)
                 .reduce((reportings, reporting, index) => {
                   if (index === 0 || reporting.integrations.some(integration => integration.status !== "Complete")) {
                     reportings.push(reporting)
                   }
                   return reportings
                 }, [])
    },
  },
}
</script>
