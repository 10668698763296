<template>
  <div class="time-tracking">
    <button v-if="!started_at" @click.stop.prevent="start" class="time-tracking--button"
            :title="`Start tracking ${type} time`">
      <i class="fi-clock time-tracking--icon"></i>
      {{ title.abbr }}
    </button>

    <button v-else @click.stop.prevent="stop" class="time-tracking--button" :title="`Stop tracking ${type} time`">
      <i class="fi-clock"></i>
      {{ title.abbr }}
      {{ format_duration(duration) }}
    </button>
  </div>
</template>

<script>
import "./time-tracking.scss"
import axios from "axios";
import moment from "moment";

moment.locale("de");
import momentDurationFormatSetup from "moment-duration-format"

momentDurationFormatSetup(moment)

import { EventBus } from '../../lib/time-tracking-event-bus';

export default {
  name: "time-tracking",
  props: {
    record: {
      required: true,
    },
    url: {
      required: false,
      default: function () {
        return `/campaigns/${this.record.id}/track_time.json`
      },
    },
    type: {
      required: true,
    },
    channel_id: {
      required: false,
      default: function () {
        return this.record.channel_id
      },
    },
    advertiser_id: {
      required: false,
    },
    init_start: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      started_at: null,
      stopped_at: null,
      duration: null,
      loading: false,
    }
  },
  methods: {
    start() {
      if (this.started_at) return
      this.started_at = new Date()
      this.stopped_at = null
      this.duration = 1
      EventBus.$emit('time-tracking-started', { time_tracking_started: this });
    },
    stop() {
      if (!this.started_at) return
      if (!this.record.id) return

      this.stopped_at = new Date()
      this.loading = true

      axios({
        method: 'post',
        url: this.url,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: {
          type: this.type,
          started_at: this.started_at,
          stopped_at: this.stopped_at,
          channel_id: this.channel_id,
          advertiser_id: this.advertiser_id,
        },
      }).then(_response => {
        this.started_at = null
        this.stopped_at = null
        this.duration = null
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.log("ERROR", error.response.data)
        alert("Error saving time tracking")
      })
    },
    data() {
      return {
        type: this.type,
        started_at: this.started_at,
      }
    },
    set_duration() {
      if (this.loading) return
      if (this.started_at) {
        if (this.stopped_at) {
          this.duration = this.stopped_at - this.started_at
        } else {
          this.duration = new Date() - this.started_at
        }
      } else {
        this.duration = null
      }
      setTimeout(this.set_duration, 1000)
    },
    format_duration(duration) {
      if (!duration) return
      return moment.duration(duration, "milliseconds").format("hh:mm:ss", { trim: false });
    },
  },
  computed: {
    title() {
      return {
        "setup": { abbr: "S", title: "Setup" },
        "management": { abbr: "M", title: "Management" },
        "reporting": { abbr: "R", title: "Reporting" },
        "creative": { abbr: "CR", title: "Creative" },
        "offer": { abbr: "O", title: "Offer" },
        "communication": { abbr: "CO", title: "Communication" },
      }[this.type]
    },
  },
  created() {
    if (this.init_start) this.start()
    this.set_duration()
    EventBus.$on('time-tracking-started', (data) => {
      if (data.time_tracking_started._uid !== this._uid) this.stop();
    });
  },
  beforeDestroy() {
    EventBus.$off('time-tracking-started');
  },
}
</script>
