<template>
  <span v-if="turnover_group" class="turnover-group-info">
    {{ turnover_group.title }}
    <i class="fi-info turnover-group-info--info" :title="turnover_group.description"></i>
  </span>
</template>

<script>
import "./turnover-group-info.scss"

export default {
  name: "turnover-group-info",
  props: {
    turnover_groups: {
      required: true,
    },
    turnover_group_id: {
      required: true,
    },
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    turnover_group() {
      return this.turnover_groups.find(turnover_group => turnover_group.id === this.turnover_group_id)
    },
  },
}
</script>
