<template>
  <span class="media-planning-campaign-target">
    <i v-if="campaign.report_target_difference !== null" class="fi-target-two"></i>
    <span v-if="campaign.report_target_difference !== null" title="Difference in target achievement">
      <template v-if="campaign.report_target_difference > 0">
        <i class="fi-plus"></i>
        {{ campaign.report_target_difference }}
      </template>
      <template v-else>
        <i class="fi-minus"></i>
        {{ -1 * campaign.report_target_difference }}
      </template>
    </span>

    <i v-if="campaign.expected_daily_target !== null" class="fi-calendar"></i>
    <span v-if="campaign.expected_daily_target !== null" title="Expected daily goal">
      {{ campaign.expected_daily_target }}
    </span>
    <span v-if="campaign.remaining_daily_target_difference !== null" title="Change in daily goal">
      <template v-if="campaign.remaining_daily_target_difference > 0">
        <i class="fi-plus"></i>
        {{ campaign.remaining_daily_target_difference }}
      </template>
      <template v-else>
        <i class="fi-minus"></i>
        {{ -1 * campaign.remaining_daily_target_difference }}
      </template>
    </span>
    <i v-if="campaign.remaining_daily_target !== null" class="fi-arrow-right"></i>
    <span v-if="campaign.remaining_daily_target !== null" title="New daily goal">
      {{ campaign.remaining_daily_target }}
      <boolean-icon v-if="campaign.remaining_daily_target === 0" title="Campaign has over delivered" :bool="false" :color="true"></boolean-icon>
    </span>
  </span>
</template>

<script>
import "./media-planning-campaign-target.scss";
import VueNumeric from 'vue-numeric';
import BooleanIcon from "../boolean-icon/boolean-icon";

export default {
  name: "media-planning-campaign-target",
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    VueNumeric,
    BooleanIcon,
  },
  data() {
    return {}
  },
  methods: {},
  computed: {},
}
</script>
