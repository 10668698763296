<template>
  <span v-if="campaign.click_rate !== null" class="campaign-click-rate">
    <abbr title="Click through rate">CTR</abbr>
    <percent-rate :rate="campaign.click_rate" :min_rate="min_rate(campaign.channel_title, 'click')">
    </percent-rate>
  </span>
</template>

<script>
import "./campaign-click-rate.scss";
import PercentRate from "../percent-rate/percent-rate.vue"
import channel_rates from "../../lib/channel-rates";

export default {
  name: "campaign-click-rate",
  props: {
    campaign: {
      required: true,
    },
    channels: {
      required: true,
    },
  },
  components: {
    PercentRate,
  },
  data() {
    return {}
  },
  methods: {
    min_rate(channel_title, rate) {
      return channel_rates(this.channels, channel_title, rate)
    },
  },
  computed: {},
}
</script>
