<template>
  <span v-if="campaign.complete_rate !== null && ['Video', 'Audio'].includes(campaign.channel_title)"
        class="campaign-complete-rate">
        <abbr v-if="campaign.channel_title === 'Video'" title="Video through rate">VTR</abbr>
        <abbr v-if="campaign.channel_title === 'Audio'" title="Listen through rate">LTR</abbr>
        <percent-rate :rate="campaign.complete_rate" :min_rate="min_rate(campaign.channel_title, 'complete')">
        </percent-rate>
  </span>
</template>

<script>
import "./campaign-complete-rate.scss";
import PercentRate from "../percent-rate/percent-rate.vue"
import channel_rates from "../../lib/channel-rates";

export default {
  name: "campaign-complete-rate",
  props: {
    campaign: {
      required: true,
    },
    channels: {
      required: true,
    },
  },
  components: {
    PercentRate,
  },
  data() {
    return {}
  },
  methods: {
    min_rate(channel_title, rate) {
      return channel_rates(this.channels, channel_title, rate)
    },
  },
  computed: {},
}
</script>
