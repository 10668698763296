import "./page.scss";
import axios from "axios";

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll("[data-href]").forEach((element) => {
    element.addEventListener("click", () => {
      window.location.href = element.dataset.href;
    })
  });

  const csrf_token_tag = document.querySelector("meta[name=csrf-token]");
  if (csrf_token_tag) {
    axios.defaults.headers.common["X-CSRF-Token"] = csrf_token_tag.content;
  }
}, false);
