import "./google-ads-customers.scss";
import Vue from 'vue/dist/vue.esm'
import GoogleAdsCustomersApp from './google-ads-customers-app.vue'
import ActionCableVue from 'actioncable-vue';

const websocket_protocol = `ws${window.location.protocol === "https:" ? "s" : ""}:`;
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'info',
  connectionUrl: `${websocket_protocol}//${window.location.host}/cable`,
  connectImmediately: true
});

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("google-ads-customers"))
       .forEach((element) => {
         const google_ads_customers = JSON.parse(element.dataset.googleAdsCustomers);
         new Vue({
                   data: {
                     google_ads_customers: google_ads_customers,
                   },
                   template: "<GoogleAdsCustomersApp :original_google_ads_customers='google_ads_customers' />",
                   components: {
                     GoogleAdsCustomersApp,
                   }
                 }).$mount(element);
       });
});
