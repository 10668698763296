<template>
  <div class="outbrain-marketers">
    <h1>
      Outbrain Marketers
    </h1>

    <div class="outbrain-marketers--commands">
      <a @click="open_outbrain_marketer_dialog()" class="outbrain-marketers--button">
        <i class="fi-plus"></i>
        Neuer Outbrain Marketer
      </a>
    </div>

    <table>
      <thead>
      <tr class="outbrain-marketers--th">
        <th class="outbrain-marketers">ID</th>
        <th class="outbrain-marketers">Titel</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="outbrain_marketer in displayable_outbrain_marketers"
          @click="open_outbrain_marketer_dialog(outbrain_marketer)"
          class="outbrain-marketers--row">
        <td>{{ outbrain_marketer.id }}</td>
        <td>{{ outbrain_marketer.title }}</td>
      </tr>
      </tbody>
    </table>

    <outbrain-marketer-dialog v-if="current_outbrain_marketer"
                              :outbrain_marketer="current_outbrain_marketer"
                              @closed_requested="current_outbrain_marketer = null">
    </outbrain-marketer-dialog>

    <portal-target name="base-modal"></portal-target>
  </div>
</template>

<script>
import "./outbrain-marketers.scss";
import OutbrainMarketerDialog from "../outbrain-marketer-dialog/outbrain-marketer-dialog-app"

export default {
  name: "outbrain-marketers",
  props: {
    original_outbrain_marketers: {
      required: true,
    },
  },
  components: {
    OutbrainMarketerDialog,
  },
  data() {
    return {
      outbrain_marketers: this.original_outbrain_marketers,
      current_outbrain_marketer: null,
    }
  },
  methods: {
    open_outbrain_marketer_dialog(outbrain_marketer = null) {
      if (outbrain_marketer) {
        this.current_outbrain_marketer = Object.assign({}, outbrain_marketer)
      } else {
        this.current_outbrain_marketer = {
          id: null,
          title: null,
        }
      }
    },
  },
  computed: {
    displayable_outbrain_marketers() {
      return this.outbrain_marketers.filter(outbrain_marketer => {
        if (outbrain_marketer.deleted) return
        if (!outbrain_marketer.persisted) return
        return true
      })
    }
  },
  channels: {
    OutbrainMarketerRegisteredChannel: {
      received(outbrain_marketer_json) {
        console.log("OutbrainMarketerRegisteredChannel received")
        const outbrain_marketer = JSON.parse(outbrain_marketer_json);
        this.outbrain_marketers.push(outbrain_marketer)
        this.$cable.subscribe({
                                channel: 'OutbrainMarketerUpdatedChannel',
                                id: outbrain_marketer.id
                              });
        this.$cable.subscribe({
                                channel: 'OutbrainMarketerDeletedChannel',
                                id: outbrain_marketer.id
                              });
      },
    },
    OutbrainMarketerUpdatedChannel: {
      received(outbrain_marketer_json) {
        console.log("OutbrainMarketerUpdatedChannel received")
        const received_outbrain_marketer = JSON.parse(outbrain_marketer_json);
        const outbrain_marketer          = this.outbrain_marketers.find(om => {
          return om.id === received_outbrain_marketer.id
        })
        if (!outbrain_marketer) return
        Object.assign(outbrain_marketer, received_outbrain_marketer);
        console.log("OutbrainMarketer updated", outbrain_marketer)
      },
    },
    OutbrainMarketerDeletedChannel: {
      received(outbrain_marketer_json) {
        console.log("OutbrainMarketerDeletedChannel received")
        const received_outbrain_marketer = JSON.parse(outbrain_marketer_json);
        const index                      = this.outbrain_marketers.findIndex(om => {
          return om.id === received_outbrain_marketer.id
        })
        if (index === -1) return
        this.outbrain_marketers.splice(index, 1);
      },
    },
  },
  mounted() {
    this.$cable.subscribe({
                            channel: 'OutbrainMarketerRegisteredChannel',
                            stream: "outbrain_marketer_registered",
                          })
    this.outbrain_marketers.forEach((outbrain_marketer => {
      this.$cable.subscribe({
                              channel: 'OutbrainMarketerUpdatedChannel',
                              id: outbrain_marketer.id
                            });
      this.$cable.subscribe({
                              channel: 'OutbrainMarketerDeletedChannel',
                              id: outbrain_marketer.id
                            });
    }))
  },
}
</script>
