<template>
  <div class="google-ads-customers">
    <h1>
      GoogleAds Customers
    </h1>

    <div class="google-ads-customers--commands">
      <a @click="open_google_ads_customer_dialog()" class="google-ads-customers--button">
        <i class="fi-plus"></i>
        Neuer GoogleAds Customer
      </a>
    </div>

    <table>
      <thead>
      <tr class="google-ads-customers--th">
        <th class="google-ads-customers">ID</th>
        <th class="google-ads-customers">Titel</th>
        <th class="google-ads-customers">Account</th>
        <th class="google-ads-customers">Gelöscht</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="google_ads_customer in displayable_google_ads_customers"
          @click="open_google_ads_customer_dialog(google_ads_customer)"
          class="google-ads-customers--row">
        <td>{{ google_ads_customer.id }}</td>
        <td>{{ google_ads_customer.title }}</td>
        <td>{{ google_ads_customer.account_name }}</td>
        <td>
          <boolean-icon v-if="google_ads_customer.deleted" :bool="!google_ads_customer.deleted" title="Gelöscht" :color="false"></boolean-icon>
        </td>
      </tr>
      </tbody>
    </table>

    <google-ads-customer-dialog v-if="current_google_ads_customer"
                                :google_ads_customer="current_google_ads_customer"
                                @closed_requested="current_google_ads_customer = null">
    </google-ads-customer-dialog>

    <portal-target name="base-modal"></portal-target>
  </div>
</template>

<script>
import "./google-ads-customers.scss";
import GoogleAdsCustomerDialog from "../google-ads-customer-dialog/google-ads-customer-dialog-app"
import BooleanIcon from "../boolean-icon/boolean-icon.vue";

export default {
  name: "google-ads-customers",
  props: {
    original_google_ads_customers: {
      required: true,
    },
  },
  components: {
    BooleanIcon,
    GoogleAdsCustomerDialog,
  },
  data() {
    return {
      google_ads_customers: this.original_google_ads_customers,
      current_google_ads_customer: null,
    }
  },
  methods: {
    open_google_ads_customer_dialog(google_ads_customer = null) {
      if (google_ads_customer) {
        this.current_google_ads_customer = Object.assign({}, google_ads_customer)
      } else {
        this.current_google_ads_customer = {
          id: null,
          title: null,
        }
      }
    },
  },
  computed: {
    displayable_google_ads_customers() {
      return this.google_ads_customers.filter(google_ads_customer => {
        if (!google_ads_customer.persisted) return
        return true
      }).sort((c1, c2) => {
        if (c1.deleted) {
          return 1
        } else {
          return -1
        }
      })
    }
  },
  channels: {
    GoogleAdsCustomerRegisteredChannel: {
      received(google_ads_customer_json) {
        console.log("GoogleAdsCustomerRegisteredChannel received")
        const google_ads_customer = JSON.parse(google_ads_customer_json);
        this.google_ads_customers.push(google_ads_customer)
        this.$cable.subscribe({
                                channel: 'GoogleAdsCustomerUpdatedChannel',
                                id: google_ads_customer.id
                              });
      },
    },
    GoogleAdsCustomerUpdatedChannel: {
      received(google_ads_customer_json) {
        console.log("GoogleAdsCustomerUpdatedChannel received")
        const received_google_ads_customer = JSON.parse(google_ads_customer_json);
        const google_ads_customer          = this.google_ads_customers.find(gac => {
          return gac.id === received_google_ads_customer.id
        })
        if (!google_ads_customer) return
        Object.assign(google_ads_customer, received_google_ads_customer);
        console.log("GoogleAdsCustomer updated", google_ads_customer)
      },
    },
  },
  mounted() {
    this.$cable.subscribe({
                            channel: 'GoogleAdsCustomerRegisteredChannel',
                            stream: "google_ads_customer_registered",
                          })
    this.google_ads_customers.forEach((google_ads_customer => {
      this.$cable.subscribe({
                              channel: 'GoogleAdsCustomerUpdatedChannel',
                              id: google_ads_customer.id
                            });
    }))
  },
}
</script>
