<template>
  <i class="channel-icon" :class="icon_class" :title="channel_title"></i>
</template>

<script>
import "./channel-icon.scss";

export default {
  name: "channel-icon",
  props: {
    channel_title: {
      required: true,
    },
  },
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {
    icon_class() {
      return {
        "Video": "fi-play-video",
        "Display": "fi-photo",
        "Native": "fi-results",
        "Audio": "fi-volume",
        "AddressableTv": "fi-monitor",
        "CTvSpot": "fi-laptop",
        "Dooh": "fi-social-windows",
        "YouTube": "fi-social-youtube",
        "SEA": "fi-social-google-plus",
      }[this.channel_title];
    },
  },
}
</script>
