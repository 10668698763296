<template>
  <div class="media-planning-campaigns">
    <template v-if="media_planning.id">
      <h3>Campaigns</h3>

      <ul id="accordion-container" class="media-planning-campaigns--accordion-container"
          data-accordion data-multi-expand="true" data-allow-all-closed="true">
        <template v-for="campaign in media_planning.campaigns">
          <media-planning-campaign :media_planning="media_planning"
                                   :campaign="campaign"
                                   :integrations="integrations"
                                   :google_ads_customers="google_ads_customers"
                                   :active_agent_accounts="active_agent_accounts"
                                   :outbrain_marketers="outbrain_marketers"
                                   :channels="channels"
                                   :can_manage="can_manage"
                                   :is_blocked="is_loading || is_blocked">
          </media-planning-campaign>
        </template>
      </ul>

      <button v-if="can_manage"
              @click="open_new_campaign = true"
              class="media-planning-campaigns--button"
              :class="disabled_button_class"
              :disabled="is_loading || is_blocked">
        <i class="fi-plus"></i>
        Campaign
      </button>

      <button v-if="is_reportable"
              @click="schedule_media_planning_reportings"
              class="media-planning-campaigns--button"
              :class="disabled_button_class"
              :disabled="is_loading || is_blocked">
        <i class="fi-refresh"></i>
        Start reportings
      </button>

      <a v-if="has_any_reports" :href="media_planning.report_xlsx_path"
         class="media-planning-campaign-reports--button">
        <i class="fi-download"></i>
        Download reports
      </a>

      <button v-if="can_manage"
              @click="open_xv_order = true"
              class="media-planning-campaigns--button"
              :class="disabled_button_class"
              :title="media_planning.xv_order_id"
              :disabled="is_loading || is_blocked">
        <i class="fi-cloud"></i>
        xv order
        <boolean-icon :bool="media_planning.xv_order_id"></boolean-icon>
      </button>

      <button v-if="can_manage && media_planning.advertiser_individual_approval"
              @click="approve_reports"
              class="media-planning-campaigns--button media-planning-campaigns--button--warning"
              :class="disabled_approve_button_class"
              :title="approval_title"
              :disabled="is_loading || is_blocked || reports_approved">
        <boolean-icon v-if="!media_planning.reports_approved_at" :bool="false" color="false"></boolean-icon>
        Approve reports
      </button>

      <media-planning-campaign-dialog v-if="open_new_campaign"
                                      :media_planning="media_planning"
                                      :integrations="integrations"
                                      :channels="channels"
                                      @closed_requested="open_new_campaign = false">
      </media-planning-campaign-dialog>

      <media-planning-xv-order v-if="open_xv_order"
                               :media_planning="media_planning"
                               :is_blocked="is_loading || is_blocked"
                               @closed_requested="open_xv_order = false"
                               @start_loading="$emit('start_loading')">
      </media-planning-xv-order>
    </template>
  </div>
</template>

<script>
import "./media-planning-campaigns.scss"
import BaseModal from "../base-modal/base-modal-app"
import MediaPlanningCampaign from "../media-planning-campaign/media-planning-campaign-app"
import MediaPlanningCampaignDialog from "../media-planning-campaign-dialog/media-planning-campaign-dialog-app"
import MediaPlanningXvOrder from "../media-planning-xv-order/media-planning-xv-order-app"
import $ from 'jquery'
import axios from "axios"
import moment from "moment"
import BooleanIcon from "../boolean-icon/boolean-icon";

export default {
  name: "media-planning-campaigns",
  props: {
    media_planning: {
      required: true,
    },
    integrations: {
      required: true,
    },
    turnover_groups: {
      required: true,
    },
    channels: {
      required: true,
    },
    google_ads_customers: {
      required: true,
    },
    active_agent_accounts: {
      required: true,
    },
    outbrain_marketers: {
      required: true,
    },
    can_manage: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    BooleanIcon,
    BaseModal,
    MediaPlanningCampaign,
    MediaPlanningCampaignDialog,
    MediaPlanningXvOrder,
  },
  data() {
    return {
      open_new_campaign: false,
      open_xv_order: false,
      new_campaign_channel_id: null,
      new_campaign_title: "",
      is_loading: false,
    }
  },
  methods: {
    schedule_media_planning_reportings() {
      if (!this.can_manage) return
      if (this.is_blocked) return
      if (this.is_loading) return

      this.media_planning.campaigns.forEach((campaign, index) => {
        if (!campaign.active_reporting) return
        if (!campaign.integrations.length) return

        setTimeout(() => {
          axios({
                  method: 'post',
                  url: `/media_plannings/${this.media_planning.id}/schedule_reporting`,
                  headers: {'X-Requested-With': 'XMLHttpRequest'},
                  data: {
                    campaign_id: campaign.id,
                    start_date: moment().subtract(3, 'days').startOf('day').format(),
                    end_date: moment().add(1, 'days').startOf('day').format(),
                    integrations: campaign.integrations.map(integration => {
                      return {
                        integration_id: integration.id,
                      }
                    }),
                  },
                })
        }, index * 10000)
      })
    },

    approve_reports() {
      if (this.is_loading) return
      if (this.is_blocked) return

      let confirmation = confirm(`Do you really want to approve the reports for ${this.media_planning.title}?`)
      if (!confirmation) return

      this.is_loading = true

      axios({
        method: 'post',
        url: `/media_plannings/approve`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: {
          media_planning_id: this.media_planning.id,
        },
      }).then(response => {
        this.is_loading = false

        if (response.data.approved_at) {
          this.media_planning.reports_approved_at = Date.parse(response.data.approved_at)
        }
      }).catch(error => {
        this.is_loading = false
        console.log("ERROR", error)
        alert("Error approving reports")
      })
    },
  },
  computed: {
    disabled_button_class() {
      if (this.is_blocked || this.is_loading) {
        return "media-planning-campaigns--button--disabled"
      } else {
        return "media-planning-campaigns--button"
      }
    },
    disabled_approve_button_class() {
      if (this.is_loading || this.reports_approved) {
        return "media-planning-campaigns--button--disabled"
      } else {
        return "media-planning-campaigns--button"
      }
    },
    has_any_reports() {
      return this.media_planning.campaigns.some(campaign => campaign.reports.length && !campaign.dummy)
    },
    is_reportable() {
      if (!this.can_manage) return false
      return this.media_planning.campaigns.some(campaign => campaign.active_reporting && !campaign.dummy)
    },
    approval_title() {
      if (this.media_planning.reports_approved_at) {
        const approved_at = moment(this.media_planning.reports_approved_at).format("LLL");
        return `Reports für ${this.media_planning.title} sind über die xv API bis ${approved_at} Uhr freigeschaltet`
      } else {
        return `Reports für ${this.media_planning.title} sind über die xv API noch nicht freigeschaltet`
      }
    },
    reports_approved() {
      if (!this.media_planning.reports_approved_at) return false
      return moment(this.media_planning.reports_approved_at).isSameOrAfter(moment().startOf('day'))
    },
  },
  mounted() {
    new Foundation.Accordion($("#accordion-container"))
  },
}
</script>
