<template>
  <div class="outbrain-marketer-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_outbrain_marketer_dialog">
        <h3 v-if="outbrain_marketer.persisted">Outbrain Marketer aktualisieren</h3>
        <h3 v-else>Neuer Outbrain Marketer</h3>

        <form @submit.prevent="save_outbrain_marketer" ref="form">
          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <label>
              ID:
              <input type="text"
                     v-model="outbrain_marketer.id"
                     :disabled="outbrain_marketer.persisted"
                     placeholder="002d03357350d1f7ee500e3c57923d09b3">
            </label>
            <label>
              Titel:
              <input type="text"
                     v-model="outbrain_marketer.title"
                     placeholder="Crossvertise - blauarbeit.de">
            </label>
          </fieldset>

          <div>
            <a v-if="outbrain_marketer.persisted"
               @click.stop.prevent="delete_outbrain_marketer"
               :class="disabled_button_class"
               class="outbrain-marketer-dialog--button--alert">
              <i class="fi-alert"></i>
              Löschen ...
            </a>
            <a @click="close_outbrain_marketer_dialog" class="outbrain-marketer-dialog--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </a>
            <button v-if="outbrain_marketer_configured" :class="disabled_button_class">
              <i class="fi-save"></i>
              <span v-if="outbrain_marketer.persisted">Outbrain Marketer aktualisieren</span>
              <span v-else>Outbrain Marketer hinzufügen</span>
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./outbrain-marketer-dialog.scss";
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios"

export default {
  name: "outbrain-marketer-dialog",
  props: {
    outbrain_marketer: {
      required: false,
      default() {
        return {
          id: null,
          title: null,
          persisted: false,
        }
      },
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      errors: null,
    }
  },
  methods: {
    save_outbrain_marketer() {
      if (!this.outbrain_marketer_configured) return
      if (this.loading) return

      this.loading = true
      this.errors = null

      if (this.outbrain_marketer.persisted) {
        axios({
          method: 'post',
          url: `/outbrain_marketers/${this.outbrain_marketer.id}/update_title`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.outbrain_marketer.id,
            title: this.outbrain_marketer.title,
          },
        }).then((_response) => {
          this.loading = false;
          this.close_outbrain_marketer_dialog()
        }).catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving Outbrain Marketer");
        });
      } else {
        axios({
          method: 'post',
          url: `/outbrain_marketers/register`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.outbrain_marketer.id,
            title: this.outbrain_marketer.title,
          },
        }).then((_response) => {
          this.loading = false;
          this.close_outbrain_marketer_dialog()
        }).catch(error => {
          this.loading = false;
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving Outbrain Marketer");
        })
      }
    },
    delete_outbrain_marketer() {
      let confirmation = confirm("Willst du den GooogleAds Customer wirklich löschen?");
      if (!confirmation) return
      axios({
        method: 'delete',
        url: `/outbrain_marketers/${this.outbrain_marketer.id}/delete`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then((_response) => {
        this.loading = false;
        this.close_outbrain_marketer_dialog()
      }).catch(error => {
        this.loading = false;
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data)
        alert("Error deleting Outbrain Marketer");
      });
    },
    close_outbrain_marketer_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    outbrain_marketer_configured() {
      if (!this.outbrain_marketer.id) return false
      if (!this.outbrain_marketer.title) return false
      if (!this.outbrain_marketer.id.match(/^([0-9]|[a-z])+$/)) return false
      return true
    },
    disabled_button_class() {
      if (this.outbrain_marketer_configured) {
        return "outbrain-marketer-dialog--button"
      } else {
        return "outbrain-marketer-dialog--disabled"
      }
    },
  },
}
</script>
