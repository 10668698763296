<template>
  <span class="percent-rate" :title="title_attr">
    <template v-if="rate !== null">
      <span v-if="rate === 0.0">
        <!-- For some reason vue-numeric doesn't always render 0.0 -->
        0,00 %
      </span>
      <vue-numeric v-if="show && this.rate !== 0.0"
                   v-bind:precision=2
                   currency="%"
                   currency-symbol-position="suffix"
                   v-bind:read-only=true
                   decimal-separator=","
                   :value="rate_percent">
      </vue-numeric>
      <boolean-icon v-if="alert_rate" :bool="false" :color="true"></boolean-icon>
    </template>
  </span>
</template>

<script>
import VueNumeric from 'vue-numeric';
import BooleanIcon from "../boolean-icon/boolean-icon";

export default {
  name: "percent-rate",
  components: {
    VueNumeric,
    BooleanIcon,
  },
  props: [
    'rate',
    'min_rate',
  ],
  methods: {},
  computed: {
    show() {
      if (this.rate === undefined) return false
      if (this.rate === null) return false
      return !isNaN(this.rate)
    },
    alert_rate() {
      if (!this.show) return false
      if (this.min_rate === null) return
      return parseFloat(this.rate) < this.min_rate
    },
    rate_percent() {
      if (!this.show) return
      return parseFloat(this.rate) * 100
    },
    title_attr() {
      if (!this.alert_rate) return
      return `< ${this.min_rate * 100} %`
    },
  }
}
</script>

<style scoped>
.percent-rate {
  white-space: nowrap;
}
</style>
