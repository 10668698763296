<template>
  <i v-if="bool" class="fi-check" :class="{'true': color}" :title="title"></i>
  <i v-else class="fi-x" :class="{'false': color}" :title="title"></i>
</template>

<script>
export default {
  name: "boolean-icon",
  props: [
    'bool',
    'title',
    'color',
  ],
}
</script>

<style scoped>
.true {
  color: #36bbb1;
}

.false {
  color: #db2828;
}
</style>
