<template>
  <span v-if="status !== null">
    <i v-if="failed" class="fi-x" :title="status"></i>
    <i v-else-if="working" class="fi-loop" :title="status"></i>
    <boolean-icon v-else :bool="complete" :color="true" :title="status"></boolean-icon>
  </span>
</template>

<script>
import BooleanIcon from "../boolean-icon/boolean-icon";

export default {
  name: "report-status",
  components: {
    BooleanIcon,
  },
  props: [
    'status',
  ],
  computed: {
    complete() {
      return this.status === 'Complete'
    },
    failed() {
      return [
        "Timeout",
        "Failed",
      ].includes(this.status)
    },
    working() {
      return [
        "Scheduled",
        "Started",
        "Working",
        "Waiting",
        "Submitted",
        "Created",
        "Working/waiting",
        "NoReportsReceived",
        "RateLimitsExceeded",
        "Checking",
      ].includes(this.status)
    },
  },
}
</script>
