import "./media-planning.scss";
import Vue from 'vue/dist/vue.esm'
import MediaPlanningApp from './media-planning-app'
import ActionCableVue from 'actioncable-vue';
import PortalVue from 'portal-vue'

Vue.use(PortalVue)

const websocket_protocol = `ws${window.location.protocol === "https:" ? "s" : ""}:`;
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'info',
  connectionUrl: `${websocket_protocol}//${window.location.host}/cable`,
  connectImmediately: true
});

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("media-planning"))
       .forEach((element) => {
         const media_planning        = JSON.parse(element.dataset.mediaPlanning);
         const channels              = JSON.parse(element.dataset.channels);
         const integrations          = JSON.parse(element.dataset.integrations);
         const turnover_groups       = JSON.parse(element.dataset.turnoverGroups);
         const google_ads_customers  = JSON.parse(element.dataset.googleAdsCustomers);
         const active_agent_accounts = JSON.parse(element.dataset.activeAgentAccounts);
         const outbrain_marketers    = JSON.parse(element.dataset.outbrainMarketers);
         const customers             = JSON.parse(element.dataset.customers);
         const can_manage            = JSON.parse(element.dataset.canManage);
         new Vue({
                   data: {
                     media_planning: media_planning,
                     channels: channels,
                     integrations: integrations,
                     turnover_groups: turnover_groups,
                     google_ads_customers: google_ads_customers,
                     active_agent_accounts: active_agent_accounts,
                     outbrain_marketers: outbrain_marketers,
                     customers: customers,
                     can_manage: can_manage,
                   },
                   template: "<MediaPlanningApp :original_media_planning='media_planning' :channels='channels' :integrations='integrations' :turnover_groups='turnover_groups' :google_ads_customers='google_ads_customers' :active_agent_accounts='active_agent_accounts' :outbrain_marketers='outbrain_marketers' :customers='customers' :can_manage='can_manage' />",
                   components: {
                     MediaPlanningApp,
                   }
                 }).$mount(element);
       });
});
