<template>
  <div class="toggle-button" @click.prevent.stop="toggle">
    <onoff-toggle v-model="current_state" :disabled="disabled" on-color="#36bbb1" width="45" height="28" margin="2"/>
    <span>{{ text }}</span>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm'
import OnoffToggle from 'vue-onoff-toggle'

Vue.use(OnoffToggle)

export default {
  name: "toggle-button",
  props: {
    state: {
      required: true,
    },
    text: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit("toggled", !this.state)
    },
  },
  computed: {
    current_state: {
      get() {
        return this.state
      },
      set(state) {
        return state
      }
    },
  },
}
</script>

<style scoped>
.toggle-button {
  cursor: pointer;
  display: inline-block;
}
</style>
