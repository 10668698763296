import { render, staticRenderFns } from "./boolean-icon.vue?vue&type=template&id=3590a988&scoped=true&"
import script from "./boolean-icon.vue?vue&type=script&lang=js&"
export * from "./boolean-icon.vue?vue&type=script&lang=js&"
import style0 from "./boolean-icon.vue?vue&type=style&index=0&id=3590a988&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3590a988",
  null
  
)

export default component.exports