export default class Report {
  constructor({
                id,
                date,
                platform_id,
                platform_title,
                campaign_integration_id,
                campaign_integration_code,
                channel_id,
                channel_title,
                campaign_id,
                impressions,
                viewable_impressions,
                impression_adjustment,
                use_viewable_impressions,
                clicks,
                click_adjustment,
                complete,
                complete_adjustment,
                video_views,
                video_view_adjustment,
                conversions,
                conversion_adjustment,
                conversions_value,
                turnover,
                cost,
                dummy,
                setup_fee,
              }) {
    this.id = id
    this.date = date
    this.platform_id = platform_id
    this.platform_title = platform_title
    this.campaign_integration_id = campaign_integration_id
    this.campaign_integration_code = campaign_integration_code
    this.channel_id = channel_id
    this.channel_title = channel_title
    this.campaign_id = campaign_id
    this.impressions = impressions
    this.viewable_impressions = viewable_impressions
    this.impression_adjustment = impression_adjustment
    this.use_viewable_impressions = use_viewable_impressions
    this.clicks = clicks
    this.click_adjustment = click_adjustment
    this.complete = complete
    this.complete_adjustment = complete_adjustment
    this.video_views = video_views
    this.video_view_adjustment = video_view_adjustment
    this.conversions = conversions
    this.conversion_adjustment = conversion_adjustment
    this.conversions_value = conversions_value
    this.turnover = turnover
    this.cost = cost
    this.dummy = dummy
    this.setup_fee = setup_fee
  }

  get click_rate() {
    if (this.get_impressions === 0) return
    return (this.get_clicks) / this.get_impressions
  }

  get complete_rate() {
    if (this.complete === null) return
    if (this.complete_adjustment === null) return
    if (this.get_impressions === null) return
    if (this.get_impressions === 0.0) return
    return this.get_complete / this.get_impressions
  }

  get video_view_rate() {
    if (this.video_views === null) return
    if (this.video_view_adjustment === null) return
    if (this.get_impressions === null) return
    if (this.get_impressions === 0.0) return
    return this.get_video_views / this.get_impressions
  }

  get conversion_rate() {
    if (this.get_conversions === null) return
    if (this.get_clicks === null) return
    if (this.get_clicks === 0.0) return
    return this.get_conversions / this.get_clicks
  }

  get get_impressions() {
    return this.real_impressions + this.impression_adjustment
  }

  get real_impressions() {
    if (this.use_viewable_impressions) {
      return this.viewable_impressions
    } else {
      return this.impressions
    }
  }

  get get_clicks() {
    return this.clicks + this.click_adjustment
  }

  get get_complete() {
    return this.complete + this.complete_adjustment
  }

  get get_video_views() {
    return this.video_views + this.video_view_adjustment
  }

  get get_conversions() {
    return this.conversions + this.conversion_adjustment
  }

  margin() {
    return this.turnover - this.cost
  }

  margin_percent() {
    if (this.turnover !== 0.0) {
      return this.margin() / this.turnover
    }
  }

  cpm() {
    if (this.cost === null) return
    if (this.get_impressions === null) return
    if (this.get_impressions === 0.0) return
    return (1000 * this.cost) / this.get_impressions
  }

  cpc() {
    if (this.cost === null) return
    if (this.get_clicks === null) return
    if (this.get_clicks === 0.0) return
    return this.cost / this.get_clicks
  }

  cpo() {
    if (this.cost === null) return
    if (this.get_conversions === null) return
    if (this.get_conversions === 0.0) return
    return this.cost / this.get_conversions
  }

  add(report) {
    const new_platform_id = (this.platform_id === report.platform_id) ? this.platform_id : null
    const new_platform_title = (this.platform_title === report.platform_title) ? this.platform_title : null
    const new_channel_id = (this.channel_id === report.channel_id) ? this.channel_id : null
    const new_channel_title = (this.channel_title === report.channel_title) ? this.channel_title : null
    const new_campaign_id = (this.campaign_id === report.campaign_id) ? this.campaign_id : null
    const new_date = (this.date === report.date) ? this.date : null

    const new_use_viewable_impressions = this.use_viewable_impressions && report.use_viewable_impressions
    const new_impressions = this.get_impressions + report.get_impressions
    const new_impression_adjustment = 0
    let new_viewable_impressions
    if (new_use_viewable_impressions) {
      new_viewable_impressions = this.viewable_impressions + report.viewable_impressions
    } else {
      new_viewable_impressions = null
    }

    const new_clicks = (this.clicks || 0) + (this.click_adjustment || 0) + (report.clicks || 0) + (report.click_adjustment || 0)
    const new_click_adjustment = 0
    const new_complete = (this.complete || 0) + (this.complete_adjustment || 0) + (report.complete || 0) + (report.complete_adjustment || 0)
    const new_complete_adjustment = 0
    const new_video_views = (this.video_views || 0) + (this.video_view_adjustment || 0) + (report.video_views || 0) + (report.video_view_adjustment || 0)
    const new_video_view_adjustment = 0
    const new_conversions = (this.conversions || 0) + (this.conversion_adjustment || 0) + (report.conversions || 0) + (report.conversion_adjustment || 0)
    const new_conversion_adjustment = 0
    const new_conversions_value = (this.conversions_value || 0) + (report.conversions_value || 0)
    const new_turnover = this.turnover + report.turnover
    const new_cost = this.cost + report.cost
    const new_setup_fee = this.setup_fee || report.setup_fee

    return new Report({
      platform_id:              new_platform_id,
      platform_title:           new_platform_title,
      channel_id:               new_channel_id,
      channel_title:            new_channel_title,
      campaign_id:              new_campaign_id,
      date:                     new_date,
      impressions:              new_impressions,
      impression_adjustment:    new_impression_adjustment,
      viewable_impressions:     new_viewable_impressions,
      use_viewable_impressions: new_use_viewable_impressions,
      clicks:                   new_clicks,
      click_adjustment:         new_click_adjustment,
      complete:                 new_complete,
      complete_adjustment:      new_complete_adjustment,
      video_views:              new_video_views,
      video_view_adjustment:    new_video_view_adjustment,
      conversions:              new_conversions,
      conversion_adjustment:    new_conversion_adjustment,
      conversions_value:        new_conversions_value,
      turnover:                 new_turnover,
      cost:                     new_cost,
      setup_fee:                new_setup_fee,
    })
  }
}
