<template>
  <div class="offer">
    <h1>
      Offer {{ offer.title }}
    </h1>

    <table>
      <thead>
      <tr>
        <th class="offer--table--col">Name</th>
        <th class="offer--table--col">Channel</th>
        <th class="offer--table--col">Time tracking</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="item in items_attributes" style="padding-bottom: 0">
        <td style="padding-bottom: 0">
          <input type="text" v-model="item.title">
        </td>

        <td style="padding-bottom: 0">
          <select v-model="item.channel_id">
            <option v-for="channel in channels" :value="channel.id">
              {{ channel.title }}
            </option>
          </select>
        </td>

        <td style="vertical-align: baseline; padding-top: 1em; padding-bottom: 0">
          <time-tracking type="offer"
                         :record="offer"
                         :url="`/offers/${offer.id}/track_time.json`"
                         :channel_id="item.channel_id"
                         :advertiser_id="offer.advertiser_id">
          </time-tracking>
        </td>
      </tr>
      </tbody>
    </table>

    <button @click.stop="add_item" class="offer--button">
      Add item
    </button>

    <button @click="save_offer"
            :disabled="!offer_configured"
            :class="save_button_class"
            ref="save_button">
      Save offer
    </button>

    <offer-time-trackings :offer="offer"></offer-time-trackings>
  </div>
</template>

<script>
import "./offer-component.scss";
import axios from "axios"
import TimeTracking from "../time-tracking/time-tracking-app.vue";
import OfferTimeTrackings from "../offer-time-trackings/offer-time-trackings-app.vue";

export default {
  name: "offer",
  props: {
    original_offer: {
      required: true,
    },
    advertisers: {
      required: true,
    },
    channels: {
      required: true,
    },
  },
  components: { OfferTimeTrackings, TimeTracking },
  data() {
    return {
      offer: this.original_offer,
      items_attributes: this.original_offer.items_attributes,
    }
  },
  methods: {
    add_item() {
      this.items_attributes.push({
        offer_id: this.offer.id,
        channel_id: null,
      })
    },
    save_offer() {
      axios({
        method: 'put',
        url: `/offers/${this.offer.id}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: this.offer_params,
      }).then((response) => {
        console.log(response)
        const saveButton = this.$refs.save_button;
        saveButton.classList.add('highlight-animation');
        setTimeout(() => {
          saveButton.classList.remove('highlight-animation');
        }, 1000);
      }).catch(error => {
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data)
        alert("Error saving offer")
      })
    },
  },
  computed: {
    offer_params() {
      return { offer: { ...this.offer, items_attributes: this.items_attributes } };
    },
    offer_configured() {
      return !this.items_attributes.some(item => {
        if (!item.title) return true
        if (!item.channel_id) return true
        return false
      })
    },
    save_button_class() {
      return this.offer_configured ? "offer--button" : "offer--button--disabled"
    },
  },
  channels: {},
  mounted() {},
}
</script>
